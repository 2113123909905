import { Box, Grid, Typography } from "@mui/material";
import { getProPickText } from "../routedround/components/couponform/components/propick";
import { Link } from 'react-router-dom';
import { Button } from "../reusable-components";
import { getRoundName } from "../common/RoundHelper";
import pridePick13Logo from '../images/opeimujackpot13.png';
import pridePick12Logo from '../images/opeimujackpot12.png';
import pridePick8Logo from '../images/opeimujackpot8.png';
import kung8 from '../images/kung8.png';
import kung12 from '../images/kung12.png';
import kung13 from '../images/kung13.png';
import video8 from '../images/video_8.jpeg';
import video12 from '../images/video_12.jpeg';
import video13 from '../images/video_13.jpeg';
import mrvegas8 from '../images/mrvegas_8.jpeg';
import mrvegas12 from '../images/mrvegas_12.jpeg';
import mrvegas13 from '../images/mrvegas_13.jpeg';
import { GetFlexNumber, GetMixMaxFlex, gradientsC, IsLeo, IsVideo, productNamesC, themeC } from "../theme/themehelper";
import { DbetEightIcon, DbetEightLadder, DbetThirteenIcon, DbetThirteenLadder, DbetTwelveIcon, DbetTwelveLadder, DbetSevenIcon } from "../images/dbeticons";

function RobotPlateSlim({ round, t, link, detailResponse, dateString, sugg }) {

    let logo = pridePick8Logo;
    let gradient = 'linear-gradient(335deg,#A8301F,#FA9547,#D09C72,#B74D01)';

    let roundName = getRoundName(round, t);
    let background = 'var(--background_4)';

    let gridBorder = "0px";
    let boxShadow = "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px";
    let minMaxFlexTextColor = 'var(--text-white)';
    let numberImage = "";
    let numberBackgroundHeight = "64px";
    let numberBackgroundWidth = "77px";
    let buttonMargin = "inherit";
    let fontFamily = 'var(--font-title)';
    let numberIcon = "";

    switch (roundName) {
        case "Opeimu 12":
            logo = pridePick12Logo;
            gradient = 'linear-gradient(335deg,#A8A8A8,#DEDEDE,#A3ADC0,#A8A8A8)';
            break;
        case "Opeimu 13":
            logo = pridePick13Logo;
            gradient = 'linear-gradient(335deg,#FBBA2E,#FFEDC0,#DD9F00)';
            break;
        default:
            break;
    }

    let padding = "6px 45.5px";

    let border = "";

    if (window.theme === themeC.Gold) {
        boxShadow = "";
        border = "1.5px solid #454545";
        gridBorder = "0px";
    }

    let mixMaxFlex = "";
    let number = "";
    let strokeColor = "";
    let logoHeight = "44px";
    let logoHeight2 = "35px";
    let logoWidth = "100px";
    let borderImageSource = "none";

    let smallText = {
        fontSize: "10px",
        color: 'var(--text-black)',
        lineHeight: "15px",
        fontWeight: "400",
        fontFamily: 'var(--font-body)',
        marginTop: '4px',
        marginBottom: '7px',
    };

    let dateText = smallText;

    if (window.theme === themeC.Medina || window.theme === themeC.MedinaAr) {
        mixMaxFlex = "Flex";
        number = "8x";
        background = 'var(--background_4)';
        gradient = "linear-gradient(0deg, #212654, #212654),linear-gradient(180deg, #F37B35 0%, rgba(243, 123, 53, 0) 100%)";
        logo = "none";
        logoHeight = "70px";
        logoHeight2 = "70px";
        logoWidth = "100%";
        borderImageSource = "linear-gradient(180deg, #F37B35 0%, rgba(243, 123, 53, 0) 100%)";
        padding = "6px 10px";
        buttonMargin = "10px 15px 0px 15px";

        strokeColor = "linear-gradient(102.58deg, #F37834 1.33%, #F6C55E 277.24%)";
        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = "Max";
            number = "13x";
            logo = "none";
            gradient = "linear-gradient(0deg, #394078, #394078),linear-gradient(180deg, #F3BA27 0%, rgba(0, 0, 0, 0) 100%)";
            strokeColor = "linear-gradient(98.24deg, #F3BA27 2.15%, #F6C55E 101.62%)";
            borderImageSource = "linear-gradient(180deg, #F3BA27 0%, rgba(0, 0, 0, 0) 100%)";
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = "Mix";
            number = "12x";
            logo = "none";
            gradient = "linear-gradient(0deg, #282E61, #282E61),linear-gradient(180deg, #93B9DE 0%, rgba(0, 0, 0, 0) 100%)";
            strokeColor = "linear-gradient(255.21deg, #C7CCF5 13.84%, #93B9DE 112.93%)";
            borderImageSource = "linear-gradient(180deg, #93B9DE 0%, rgba(0, 0, 0, 0) 100%)";
        }

        gridBorder = "1px";

        border = "double " + gridBorder + " transparent";
    }

    if (window.theme === themeC.Blue2) {
        mixMaxFlex = "Mini";
        number = "8";
        background = 'var(--background_4)';
        gradient = gradientsC.Blue2.Flex;
        logo = "none";
        logoHeight = "70px";
        logoHeight2 = "70px";
        logoWidth = "100%";

        if (roundName.indexOf("13") > -1) {
            mixMaxFlex = "Major";
            number = "13";
            logo = "none";
            gradient = gradientsC.Blue2.Max;
        }

        if (roundName.indexOf("12") > -1) {
            mixMaxFlex = "Minor";
            number = "12";
            logo = "none";
            gradient = gradientsC.Blue2.Mix;
        }

        border = "double " + gridBorder + " transparent";
    }

    if (window.theme === themeC.Orange) {
        mixMaxFlex = productNamesC.Orange.Flex;
        number = "8";
        background = 'var(--background_4)';
        gradient = gradientsC.Orange.Flex;
        boxShadow = "0px 1px 3px 0px #00000040"
        logo = "none";
        logoHeight = "70px";
        logoHeight2 = "70px";
        logoWidth = "100%";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = productNamesC.Orange.Max;
            number = "13";
            logo = "none";
            gradient = gradientsC.Orange.Max;
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = productNamesC.Orange.Mix;
            number = "12";
            logo = "none";
            gradient = gradientsC.Orange.Mix;
        }

        border = "double " + gridBorder + " transparent";
    }

    if (window.theme === themeC.Wood) {
        mixMaxFlex = productNamesC.Orange.Flex;
        number = "8";
        background = 'var(--background_4)';
        gradient = gradientsC.Wood.Flex;
        boxShadow = "0px 1px 3px 0px #00000040"
        logo = "none";
        logoHeight = "70px";
        logoHeight2 = "70px";
        logoWidth = "100%";
        padding = "12px 35px";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = productNamesC.Orange.Max;
            number = "13";
            logo = "none";
            gradient = gradientsC.Wood.Max;
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = productNamesC.Orange.Mix;
            number = "12";
            logo = "none";
            gradient = gradientsC.Wood.Mix;
        }

        border = "double " + gridBorder + " transparent";
    }

    if (window.theme === themeC.Lion) {
        mixMaxFlex = productNamesC.Orange.Flex;
        number = "8";
        background = 'var(--background_4)';
        gradient = gradientsC.Lion.Flex;
        boxShadow = "0px 1px 3px 0px #00000040"
        logo = "none";
        logoHeight = "70px";
        logoHeight2 = "70px";
        logoWidth = "100%";
        padding = "12px 35px";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = productNamesC.Orange.Max;
            number = "13";
            logo = "none";
            gradient = gradientsC.Lion.Max;
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = productNamesC.Orange.Mix;
            number = "12";
            logo = "none";
            gradient = gradientsC.Lion.Mix;
        }

        border = "double " + gridBorder + " transparent";
    }

    if (window.theme === themeC.Green) {
        mixMaxFlex = productNamesC.Orange.Flex;
        number = "8";
        background = 'var(--background_4)';
        gradient = gradientsC.Green.Flex;
        boxShadow = "0px 1px 3px 0px #00000040"
        logo = "none";
        logoHeight = "70px";
        logoHeight2 = "70px";
        logoWidth = "100%";
        padding = "12px 35px";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = productNamesC.Orange.Max;
            number = "13";
            logo = "none";
            gradient = gradientsC.Green.Max;
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = productNamesC.Orange.Mix;
            number = "12";
            logo = "none";
            gradient = gradientsC.Green.Mix;
        }

        border = "double " + gridBorder + " transparent";
    }

    if ((window.theme === themeC.Kung)) {
        mixMaxFlex = GetMixMaxFlex(round.name, window.theme);
        number = GetFlexNumber(round.name);
        background = 'var(--background_4)';
        gradient = gradientsC.Kung.Flex;
        logo = "none";
        logoHeight = "75px";
        logoHeight2 = "75px";
        logoWidth = "100%";
        minMaxFlexTextColor = "#27FFAA";
        numberImage = kung8;
        numberBackgroundHeight = "46px";
        numberBackgroundWidth = "34.69px";
        buttonMargin = "10px 15px 0px 15px";
        padding = "12px 35px";

        dateText = {
            fontSize: "14px",
            color: "#78C4F8",
            lineHeight: "15px",
            fontWeight: "400",
            fontFamily: 'var(--font-body)',
            marginTop: '15px',
            marginBottom: '0px',
        };

        smallText = {
            fontSize: "14px",
            color: "#78C4F8",
            lineHeight: "15px",
            fontWeight: "400",
            fontFamily: 'var(--font-body)',
            marginTop: '10px',
            marginBottom: '10px',
        };

        if (round.name.indexOf("Max") > -1) {
            number = "13";
            logo = "none";
            gradient = gradientsC.Kung.Max;
            minMaxFlexTextColor = "#34C2FF";
            numberImage = kung13;
            numberBackgroundHeight = "46px";
            numberBackgroundWidth = "55px";
        }

        if (round.name.indexOf("Mix") > -1) {
            number = "12";
            logo = "none";
            gradient = gradientsC.Kung.Mix;
            minMaxFlexTextColor = "#EE8DFF";
            numberImage = kung12;
            numberBackgroundHeight = "46px";
            numberBackgroundWidth = "56.93px";
        }

        border = "double " + gridBorder + " transparent";
        numberImage = `url(${numberImage})`;
    }

    if ((window.theme === themeC.Dbet)) {
        mixMaxFlex = GetMixMaxFlex(round.name, window.theme);
        number = GetFlexNumber(round.name);
        background = 'var(--background_4)';
        gradient = gradientsC.Dbet.Flex;
        logo = "none";
        logoHeight = "87px";
        logoHeight2 = "80px";
        logoWidth = "100%";
        minMaxFlexTextColor = "#F4C004";
        numberImage = "";
        numberBackgroundHeight = "46px";
        numberBackgroundWidth = "75px";
        buttonMargin = "10px 15px 0px 15px";
        padding = "8px 35px";
        fontFamily = "Righteous";
        numberIcon =
            <Grid container direction="row" alignItems="center" >
                <Grid sx={{ marginRight: "7px", paddingLeft: "18px" }}>
                    {DbetEightIcon(48, 35.53)}
                </Grid>
                {DbetEightLadder(20, 12)}
            </Grid>

        if (round.name.indexOf("Hockey") > -1) {
            numberIcon =
            <Grid container direction="row" alignItems="center" >
                <Grid sx={{ marginRight: "7px", paddingLeft: "18px" }}>
                    {DbetSevenIcon(48, 35.53)}
                </Grid>
                {DbetEightLadder(20, 12)}
            </Grid>
        }

        dateText = {
            fontSize: "14px",
            color: "#5E5E5E",
            lineHeight: "16.41px",
            fontWeight: "700",
            fontFamily: 'var(--font-body)',
            marginTop: '15px',
            marginBottom: '0px',
        };

        if (round.name.indexOf("Max") > -1) {
            number = "13";
            gradient = gradientsC.Dbet.Max;
            numberBackgroundHeight = "46px";
            numberBackgroundWidth = "100px";
            numberIcon =
                <Grid container direction="row" alignItems="center" >
                    <Grid sx={{ marginRight: "-15px", marginLeft: "0px" }}>
                        {DbetThirteenIcon(48, 97)}
                    </Grid>
                    {DbetThirteenLadder(20, 12)}
                </Grid>
        }

        if (round.name.indexOf("Mix") > -1) {
            number = "12";
            gradient = gradientsC.Dbet.Mix;
            numberBackgroundHeight = "46px";
            numberBackgroundWidth = "100px";
            numberIcon =
                <Grid container direction="row" alignItems="center" >
                    <Grid sx={{ marginRight: "-15px", paddingLeft: "0px" }}>
                        {DbetTwelveIcon(48, 100)}
                    </Grid>
                    {DbetTwelveLadder(20, 12)}
                </Grid>
        }
    }

    if ((window.theme === themeC.Video)) {
        mixMaxFlex = GetMixMaxFlex(round.name, window.theme);
        background = 'var(--background_4)';
        gradient = `url(${video8})`;
        logo = "none";
        logoHeight = "70px";
        logoHeight2 = "80px";
        logoWidth = "100%";
        minMaxFlexTextColor = "#7EFFB2";
        numberImage = "";
        buttonMargin = "10px 15px 0px 15px";
        padding = "8px 35px";
        fontFamily = "Tilt Warp";
        numberIcon = "";

        dateText = {
            fontSize: "14px",
            color: "#7E7E7E",
            lineHeight: "16.41px",
            fontWeight: "700",
            fontFamily: 'var(--font-body)',
            marginTop: '15px',
            marginBottom: '0px',
        };

        smallText = {
            fontSize: "14px",
            color: "#DD9E37",
            lineHeight: "16.41px",
            fontWeight: "400",
            fontFamily: 'var(--font-body)',
            marginTop: '10px',
            marginBottom: '10px',
        };

        if (round.name.indexOf("Max") > -1) {
            logo = "none";
            gradient = `url(${video13})`;
            minMaxFlexTextColor = "#FFF3B7";
        }

        if (round.name.indexOf("Mix") > -1) {
            logo = "none";
            gradient = `url(${video12})`;
            minMaxFlexTextColor = "#D47EDD";
        }
        border = "double " + gridBorder + " transparent";
    }

    if ((window.theme === themeC.MrVegas)) {
        mixMaxFlex = "";
        background = 'var(--background_4)';
        gradient = `url(${mrvegas8})`;
        logo = "none";
        logoHeight = "80px";
        logoHeight2 = "80px";
        logoWidth = "100%";
        numberImage = "";
        buttonMargin = "10px 15px 0px 15px";
        padding = "8px 35px";
        fontFamily = "Tilt Warp";
        numberIcon = "";

        dateText = {
            fontSize: "14px",
            color: "#8AD798",
            lineHeight: "16.41px",
            fontWeight: "400",
            fontFamily: 'var(--font-body)',
            marginTop: '15px',
            marginBottom: '0px',
        };

        smallText = {
            fontSize: "14px",
            color: "#8AD798",
            lineHeight: "16.41px",
            fontWeight: "400",
            fontFamily: 'var(--font-body)',
            marginTop: '10px',
            marginBottom: '10px',
        };

        if (round.name.indexOf("Max") > -1) {
            logo = "none";
            gradient = `url(${mrvegas13})`;
        }

        if (round.name.indexOf("Mix") > -1) {
            logo = "none";
            gradient = `url(${mrvegas12})`;
        }
        border = "double " + gridBorder + " transparent";
    }

    if (window.theme === themeC.Sultan) {
        mixMaxFlex = "Flex";
        number = "8";
        background = 'var(--background_4)';
        gradient = gradientsC.Sultan.Flex;
        logo = "none";
        logoHeight = "70px";
        logoHeight2 = "70px";
        logoWidth = "100%";

        if (roundName.indexOf("Max") > -1) {
            mixMaxFlex = "Max";
            number = "13";
            logo = "none";
            gradient = gradientsC.Sultan.Max;
        }

        if (roundName.indexOf("Mix") > -1) {
            mixMaxFlex = "Mix";
            number = "12";
            logo = "none";
            gradient = gradientsC.Sultan.Mix;
        }

        border = "double " + gridBorder + " transparent";
    }

    if (window.theme === themeC.Betconstruct || window.theme === themeC.Betconstructme || window.theme === themeC.Elit || window.theme === themeC.Metro
        || window.theme === themeC.Cwinz || window.theme === themeC.Betbeto
    ) {
        mixMaxFlex = t('flex');
        if (window.theme === themeC.Cwinz) {
            mixMaxFlex = productNamesC.Cwinz.Flex;
        }
        if (window.theme === themeC.Betbeto) {
            mixMaxFlex = productNamesC.Betbeto.Flex;
        }
        number = "8";
        background = 'var(--background_4)';
        gradient = gradientsC.Betconstruct.Flex;
        logo = "none";
        logoHeight = "70px";
        logoHeight2 = "70px";
        logoWidth = "100%";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = t('max');
            if (window.theme === themeC.Cwinz || window.theme === themeC.Betbeto) {
                mixMaxFlex = productNamesC.Cwinz.Max;
            }
            number = "13";
            logo = "none";
            gradient = gradientsC.Betconstruct.Max;
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = t('mix');
            if (window.theme === themeC.Cwinz || window.theme === themeC.Betbeto) {
                mixMaxFlex = productNamesC.Cwinz.Mix;
            }
            number = "12";
            logo = "none";
            gradient = gradientsC.Betconstruct.Mix;
        }

        border = "double " + gridBorder + " transparent";
    }

    if (window.theme === themeC.Betconstruct || window.theme === themeC.Betconstructme || window.theme === themeC.Elit || window.theme === themeC.Metro
        || window.theme === themeC.Cwinz || window.theme === themeC.Betbeto
    ) {
        padding = "6px 25px";
    }



    const reportSelection = () => {
        window.gtag("event", "select_content", {
            content_type: "round",
            content_id: "pro_pick_box"
        });
    }

    return (

        <Grid sx={{
            width: "160px",
            border: border,
            borderRadius: 'var(--radius)',
            backgroundImage: borderImageSource,
            backgroundOrigin: "border-box",
            backgroundClip: "padding-box, border-box",
            padding: gridBorder,
            margin: "5px",
            boxShadow: boxShadow
        }}>
            <Grid container direction='column' alignItems='center'
                sx={{
                    background: background,
                    margin: '0px',
                    borderRadius: 'var(--radius)'
                }}>

                <Grid container justifyContent='center' alignItems='center' sx={{
                    backgroundImage: gradient,
                    width: '100%',
                    height: logoHeight,
                    backgroundSize: window.theme === themeC.MrVegas ? 'cover' : 'contain',
                    borderTopLeftRadius: 'var(--radius)',
                    borderTopRightRadius: 'var(--radius)'
                }}>
                    <Grid item sx={{
                        width: logoWidth,
                        height: logoHeight2,
                        backgroundImage: logo === "none" ? 'none' : `url(${logo})`,
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}>
                        {(window.theme === themeC.Medina || window.theme === themeC.MedinaAr) &&
                            <Grid direction="column" container alignItems="center" sx={{ marginTop: "10px" }}>
                                <Typography sx={{
                                    fontSize: "12px",
                                    fontFamily: "monumentextended",
                                    color: 'var(--text-black)',
                                    fontWeight: "400",
                                    textTransform: "uppercase",
                                    marginTop: "0px"
                                }}>
                                    {mixMaxFlex}
                                </Typography>
                                <Typography sx={{
                                    fontSize: "40px",
                                    fontFamily: "monumentextended",
                                    background: strokeColor,
                                    backgroundClip: "text",
                                    color: "transparent",
                                    display: "inline-block",
                                    fontWeight: "800",
                                    marginTop: "-10px"
                                }}>
                                    {number}
                                </Typography>
                            </Grid>
                        }
                        {(window.theme === themeC.Blue2 || window.theme === themeC.Sultan || IsLeo(window.theme)) &&
                            <Grid direction="column" container alignItems="center" sx={{ marginTop: "5px" }}>
                                <Typography sx={{
                                    fontSize: "12px",
                                    fontFamily: 'var(--font-title)',
                                    color: 'var(--text-white)',
                                    fontWeight: "400",
                                    textTransform: "uppercase",
                                    marginTop: "0px"
                                }}>
                                    {mixMaxFlex}
                                </Typography>
                                <Typography sx={{
                                    fontSize: "40px",
                                    fontFamily: 'var(--font-title)',
                                    color: 'var(--text-white)',
                                    fontWeight: "800",
                                    marginTop: "-10px"
                                }}>
                                    {number}
                                </Typography>
                            </Grid>
                        }

                        {IsVideo(window.theme) &&
                            <Grid direction="column" container alignItems="center" sx={{ marginTop: "5px" }}>
                                <Typography sx={{
                                    fontSize: "12px",
                                    fontFamily: fontFamily,
                                    color: minMaxFlexTextColor,
                                    fontWeight: window.theme === themeC.Kung ? "700" : "400",
                                    textTransform: window.theme === themeC.Video ? "none" : "uppercase",
                                    marginTop: window.theme === themeC.Video ? "-3px" : "0px",

                                }}>
                                    {mixMaxFlex}
                                </Typography>
                                <Box
                                    sx={{
                                        backgroundImage: numberImage,
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                        height: numberBackgroundHeight,
                                        width: numberBackgroundWidth
                                    }}
                                >
                                    {numberIcon}
                                </Box>
                            </Grid>
                        }
                        {(window.theme === themeC.Betconstruct || window.theme === themeC.Betconstructme || window.theme === themeC.Elit || window.theme === themeC.Metro
                            || window.theme === themeC.Cwinz || window.theme === themeC.Betbeto
                        ) &&
                            <Grid direction="column" container alignItems="center" sx={{ marginTop: "5px" }}>
                                <Typography sx={{
                                    fontSize: "12px",
                                    fontFamily: 'var(--font-title)',
                                    color: 'var(--text-white)',
                                    fontWeight: "400",
                                    textTransform: "uppercase",
                                    marginTop: "0px"
                                }}>
                                    {mixMaxFlex}
                                </Typography>
                                <Typography sx={{
                                    fontSize: "40px",
                                    fontFamily: 'var(--font-title)',
                                    color: 'var(--text-white)',
                                    fontWeight: "800",
                                    marginTop: "-10px"
                                }}>
                                    {number}
                                </Typography>
                            </Grid>
                        }
                    </Grid>

                </Grid>

                <Grid container direction='column' alignItems='center'>
                    <Typography sx={dateText}>{dateString}</Typography>
                    <Typography sx={{
                        fontSize: "20px",
                        color: 'var(--text-black)',
                        lineHeight: "30px",
                        fontWeight: window.theme === themeC.Dbet ? "900" : "700",
                        fontFamily: IsLeo(window.theme) ? 'var(--font-title)' : 'var(--font-body)',
                        marginTop: '0px',
                        marginBottom: '5px'

                    }}>{detailResponse.round &&
                        getProPickText(sugg.rows, detailResponse.round.operatorStakePerRow, detailResponse.round.operatorFeePerRow ?? { amount: 0 }, t)}</Typography>

                    <Link to={link}>
                        <div onClick={() => reportSelection()}>
                            {window.theme !== themeC.Gold &&
                                <Button margin={buttonMargin} padding={padding}>{t('bet_now')}</Button>
                            }
                            {window.theme === themeC.Gold &&
                                <Button padding="6px 45.5px" textColor="#FFFFFF" color="#4CB34A">{t('bet_now')}</Button>
                            }
                        </div>
                    </Link>
                    <Typography sx={smallText}>{sugg.rows} {t('rows')}</Typography>
                </Grid>
            </Grid>
        </Grid>

    );

}

export default RobotPlateSlim;