import { QueryResult, useQuery, useQueryCache } from "react-query";
import fetchApi from "../backend/fetchApi";
import { usePlayer } from "../player/playerContext";
import { CouponShare } from "./types";

const useCouponShare = (couponShareId: number) => {

    const [player] = usePlayer() as any;
    const queryCache = useQueryCache();
    let couponShareUrl = player && player._links.couponShares.href;
    if (couponShareUrl) {
        couponShareUrl.replace("couponShares", "couponShare");
        couponShareUrl += "/" + couponShareId;
    }

    const key = 'couponShare' + couponShareId;

    let result = useQuery<CouponShare, unknown>([key, couponShareUrl], () => {

        return fetchApi(couponShareUrl)
            .then(response => response.json()).then(r => {
                return r;
            })
            .catch(err => {
                return null;
            })
    }
        , {
            enabled: !!player,
            initialStale: true,
        })

    const reloadCouponShare = () => queryCache.invalidateQueries([key, couponShareUrl]);

    return [
        result.data,
        { ...result, reloadCouponShare }
    ] as [CouponShare | undefined, QueryResult<CouponShare> & {
        reloadCouponShare: () => Promise<any>
    }]
}

export default useCouponShare;
