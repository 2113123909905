export const TEAMS_SV = {
    "Sweden": "Sverige",
    "Norway": "Norge",
    "Brazil": "Brasilien",
    "North Macedonia": "Nordmakedonien",
    "Northern Ireland": "Nordirland",
    "Palestine": "Palestina",
    "Iraq": "Irak",
    "Cameroon": "Kamerun",
    "Libya": "Libyen",
    "Estonia": "Estland",
    "Moldova": "Moldavien",
    "Luxembourg": "Luxemburg",
    "Spain": "Spanien",
    "France": "Frankrike",
    "Germany": "Tyskland",
    "Italy": "Italien",
    "Belgium": "Belgien",
    "Slovakia": "Slovakien",
    "Bulgaria": "Bulgarien",
    "Saudi Arabia": "Saudiarabien",
    "China PR": "Kina",
    "Australia": "Australien",
    "Korea Republic": "Sydkorea",
    "Jordan": "Jordanien",
    "Indonesia": "Indonesien",
    "Kyrgyz Republic": "Kyrgyzstan",
    "South Africa": "Sydafrika",    
}

