import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { ReducedSystem } from '../../models/round';
import { Button } from '../../reusable-components';
import { getReducedFiendlyName } from '../../common/RoundHelper';
import { Box, Grid, SxProps, Theme, Typography } from '@mui/material';

const ReducedSystemSelector = ({ reducedSystems, onChange, onReset, numGames }:
  {
    reducedSystems: ReducedSystem[], onChange: (id: string) => void, onReset: () => void,
    numGames: number
  }) => {

  const [selectedSystem, setSelectedSystem] = useState('');
  const { t } = useTranslation();

  function getReducedForGuarantee(reducedSystems: ReducedSystem[], guarantee: number) {

    const systems12 = [
      'R_0_7_16',
      'R_9_0_1356',
      'R_3_3_24',
      'R_4_0_9',
      'R_4_4_144',
    ];

    const systems11 = [
      'R_5_3_36',
      'R_9_0_243',
      'R_5_5_108',
      'R_2_5_12',
      'R_10_0_567',
      'R_6_3_72',
      'R_11_0_729',
      'R_7_0_36',
      'R_4_4_24',
      'R_7_2_108',
      'R_4_5_48',
      'R_4_6_72',
      'R_8_0_81',
      'R_8_2_324',
      'R_4_7_144',
      'R_8_3_504',
      'R_9_0_222',
      'R_5_0_18'
    ];

    let systems: ReducedSystem[] = [];

    reducedSystems.forEach(system => {

      if (guarantee === -1 && systems12.indexOf(system.name) > -1)
        systems.push(system);

      if (guarantee === -2 && systems11.indexOf(system.name) > -1)
        systems.push(system);

    });

    return systems.sort((a, b) => a.nRows - b.nRows);
  }

  function onClick(system: string) {

    if (system === selectedSystem) {
      setSelectedSystem('');
      onReset();
    }
    else {
      setSelectedSystem(system);
    }
  }

  const gridStyle: SxProps<Theme> = {
    background: 'var(--background_2)',
    width: "360px",
    padding: "20px",
    margin: "0px 0 0px 0"
  }

  const titleStyle: SxProps<Theme> = {
    fontWeight: "bold",
    fontSize: "14px",
    margin: "10px"
  }

  const boxStyle: SxProps<Theme> = {
    fontWeight: "bold",
    padding: "11px 11px 9px 11px",
    margin: "4px",
    background: "var(--sign-background-not-selected)",
    color: "var(--sign-color)",
    cursor: "pointer"
  }

  const boxStyleSelected: SxProps<Theme> = {
    fontWeight: "bold",
    padding: "11px 11px 9px 11px",
    margin: "4px",
    background: "var(--sign-background)",
    color: "var(--sign-color-selected)",
    cursor: "pointer"
  }

  return (
    <Grid container justifyContent="center" sx={{ width: "100%" }}>
      <Grid sx={gridStyle}>
        <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>R-system</Typography>

        <Typography sx={titleStyle}>{numGames - 1} - {t('system_guarantee')}</Typography>

        <Grid container justifyContent="center">
          {getReducedForGuarantee(reducedSystems, -1).map(({ name }, i) =>
            <Box sx={selectedSystem === name ? boxStyleSelected : boxStyle} onClick={() => onClick(name)}
              key={`rs ${i}`}>{getReducedFiendlyName(name)}</Box>)}
        </Grid>

        <Typography sx={titleStyle}>{numGames - 2} - {t('system_guarantee')}</Typography>

        <Grid container justifyContent="center">
          {getReducedForGuarantee(reducedSystems, -2).map(({ name }, i) =>
            <Box sx={selectedSystem === name ? boxStyleSelected : boxStyle} onClick={() => onClick(name)}
              key={`rs ${i}`}>{getReducedFiendlyName(name)}</Box>)}
        </Grid>
        <Typography sx={titleStyle}>R-{t('full_cover')}-{t('half_cover')}-{t('number_of_rows')}</Typography>
      </Grid>

      <Button margin="10px" type="button" onClick={() => onChange(selectedSystem)}>{t('select')}</Button>

      <Typography sx={{ textAlign: "left", fontSize: "14px", marginTop: "20px" }}>
        {t('reduced_disclaimer')}
      </Typography>
    </Grid>
  );
}

export default ReducedSystemSelector;