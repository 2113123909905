import { formatStringCurrency } from "../common/CurrencyFormatter";
import { getDateString, getJackpot, getRoundName } from "../common/RoundHelper";
import useDetailRound from "../rounds/useDetailRound";
import { Link } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';

import medina13 from '../images/medina_13.png';
import medina12 from '../images/medina_12.png';
import medina8 from '../images/medina_8.png';
import medinaBackground8 from '../images/medina_background_8.png';
import medinaBackground12 from '../images/medina_background_12.png';
import medinaBackground13 from '../images/medina_background_13.png';
import pridePick13 from '../images/PridePick13.png';
import pridePick12 from '../images/PridePick12.png';
import pridePick8 from '../images/PridePick8.png';
import pridePick13Logo from '../images/opeimujackpot13.png';
import pridePick12Logo from '../images/opeimujackpot12.png';
import pridePick8Logo from '../images/opeimujackpot8.png';
import kung8 from '../images/kung8.png';
import kung12 from '../images/kung12.png';
import kung13 from '../images/kung13.png';
import video8 from '../images/video_8.jpeg';
import video12 from '../images/video_12.jpeg';
import video13 from '../images/video_13.jpeg';
import mrvegas8 from '../images/mrvegas_8_big.jpeg';
import mrvegas12 from '../images/mrvegas_12_big.jpeg';
import mrvegas13 from '../images/mrvegas_13_big.jpeg';
import CountdownTimer from "../common/CountdownTimer";
import { Button } from "../reusable-components";
import { GetFlexNumber, GetMixMaxFlex, gradientsC, IsLeo, IsVideo, productNamesC, themeC } from "../theme/themehelper";
import { DbetArrowRight, DbetEightIcon, DbetEightLadder, DbetSevenIcon, DbetThirteenIcon, DbetThirteenLadder, DbetTwelveIcon, DbetTwelveLadder } from "../images/dbeticons";

function RoundBoxWithButton({ round, t }) {
    const link = `/rounds/${round.id}`;

    const detailRoundResponse = useDetailRound(round.id);

    const turnoverAmount = round.operatorTurnover ? round.operatorTurnover.amount : 'NA';
    const turnoverCurrency = round.operatorTurnover ? round.operatorTurnover.currency : 'NA';
    const dateString = getDateString(round, t);

    let theme = window.theme;

    let smallText = {
        fontSize: "14px",
        lineHeight: "21px",
        color: 'var(--text-black)',
        fontWeight: "400",
        fontFamily: 'var(--font-title)'
    };

    let smallTitle = {
        fontSize: "16px",
        color: 'var(--text-black)',
        lineHeight: "20px",
        fontWeight: "700",
        fontFamily: 'var(--font-title)',
    }

    let smallTitleUpper = {
        fontSize: "16px",
        color: 'var(--text-black)',
        lineHeight: "20px",
        fontWeight: "700",
        fontFamily: 'var(--font-title)',
        textTransform: 'uppercase'
    }

    let jackpot = getJackpot(turnoverCurrency, detailRoundResponse.round);

    let roundName = getRoundName(round, t);

    let roundImage = medinaBackground8;
    let roundLogo = medina8;
    let topLogo = undefined;
    let mixMaxFlex = "Flex";
    let number = "8x";
    let strokeColor = "linear-gradient(102.58deg, #F37834 1.33%, #F6C55E 277.24%)";
    let borderRadius = "12px";
    let borderImageSource = "linear-gradient(180deg, #F37B35 0%, rgba(243, 123, 53, 0) 100%)";
    let topHeight = "140px";
    let border = "double 1px transparent";
    let boxShadow = "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px";
    let boxPadding = "1px";
    let widthBox = "340px";
    let minMaxFlexTextColor = 'var(--text-white)';
    let numberImage = "";
    let numberBackgroundHeight = "64px";
    let numberBackgroundWidth = "77px";
    let borderRadiusTop = "";
    let textTitle = 'var(--text-black)';
    let fontWeight = 400;
    let fontWeightMoney = 700;
    let fontFamily = 'var(--font-title)';
    let numberIcon = "";


    if (round.name.indexOf("Max") > -1) {
        roundImage = medinaBackground13;
        roundLogo = medina13;
        strokeColor = "linear-gradient(98.24deg, #F3BA27 2.15%, #F6C55E 101.62%)";
        mixMaxFlex = "Max";
        number = "13x";
        borderImageSource = "linear-gradient(180deg, #F3BA27 0%, rgba(0, 0, 0, 0) 100%)";
    }

    if (round.name.indexOf("Mix") > -1) {
        roundImage = medinaBackground12;
        roundLogo = medina12;
        strokeColor = "linear-gradient(255.21deg, #C7CCF5 13.84%, #93B9DE 112.93%)";
        mixMaxFlex = "Mix";
        number = "12x";
        borderImageSource = "linear-gradient(180deg, #93B9DE 0%, rgba(0, 0, 0, 0) 100%)";
    }

    roundImage = `url(${roundImage})`;
    roundLogo = `url(${roundLogo})`;

    if (theme === themeC.Blue2) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: 'var(--text-black)',
            fontWeight: "400",
            fontFamily: 'var(--font-title)'
        };

        smallTitle = {
            fontSize: "16px",
            color: 'var(--text-black)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
        }

        smallTitleUpper = {
            fontSize: "16px",
            color: 'var(--text-black)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
            textTransform: 'uppercase'
        }

        roundImage = "linear-gradient( 0deg, var(--background_4) 100%, var(--background_4) 100%)";
        roundLogo = gradientsC.Blue2.Flex;
        mixMaxFlex = "Mini";
        number = "8";
        strokeColor = "none";
        borderRadius = "0px";
        borderImageSource = "none";
        widthBox = "300px";

        if (roundName.indexOf("13") > -1) {
            mixMaxFlex = "Major";
            number = "13";
            roundLogo = gradientsC.Blue2.Max;
        }

        if (roundName.indexOf("12") > -1) {
            mixMaxFlex = "Minor";
            number = "12";
            roundLogo = gradientsC.Blue2.Mix;
        }
    }

    if (theme === themeC.Orange) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: 'var(--text-black)',
            fontWeight: "400",
            fontFamily: 'var(--font-title)'
        };

        smallTitle = {
            fontSize: "16px",
            color: 'var(--text-black)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
        }

        smallTitleUpper = {
            fontSize: "16px",
            color: 'var(--text-black)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
            textTransform: 'uppercase'
        }        

        roundImage = "linear-gradient( 0deg, var(--background_4) 100%, var(--background_4) 100%)";
        roundLogo = gradientsC.Orange.Flex;
        mixMaxFlex = round.name.indexOf("Hockey") > -1 ? productNamesC.Orange.Hockey : productNamesC.Orange.Flex;
        number = round.name.indexOf("Hockey") > -1 ? "7" : "8";
        strokeColor = "none";
        borderRadius = "4px";
        borderRadiusTop = "4px 4px 0px 0px";
        borderImageSource = "none";
        widthBox = "300px";
        boxShadow = "0px 1px 3px 0px #00000040";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = productNamesC.Orange.Max;
            number = "13";
            roundLogo = gradientsC.Orange.Max;
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = productNamesC.Orange.Mix;
            number = "12";
            roundLogo = gradientsC.Orange.Mix;
        }
    }

    if (window.theme === themeC.Wood) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: 'var(--text-black)',
            fontWeight: "400",
            fontFamily: 'var(--font-body)'
        };

        smallTitle = {
            fontSize: "16px",
            color: 'var(--text-black)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
        }

        smallTitleUpper = {
            fontSize: "16px",
            color: 'var(--text-black)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
            textTransform: 'uppercase'
        }

        roundImage = "linear-gradient( 0deg, var(--background_4) 100%, var(--background_4) 100%)";
        roundLogo = gradientsC.Wood.Flex;
        mixMaxFlex = round.name.indexOf("Hockey") > -1 ? productNamesC.Orange.Hockey : productNamesC.Orange.Flex;
        number = round.name.indexOf("Hockey") > -1 ? "7" : "8";
        strokeColor = "none";
        borderRadius = "4px";
        borderRadiusTop = "4px 4px 0px 0px";
        borderImageSource = "none";
        widthBox = "300px";
        boxShadow = "0px 1px 3px 0px #00000040";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = productNamesC.Orange.Max;
            number = "13";
            roundLogo = gradientsC.Wood.Max;
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = productNamesC.Orange.Mix;
            number = "12";
            roundLogo = gradientsC.Wood.Mix;
        }
    }

    if (window.theme === themeC.Lion) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: 'var(--text-black)',
            fontWeight: "400",
            fontFamily: 'var(--font-title)'
        };

        smallTitle = {
            fontSize: "16px",
            color: 'var(--text-black)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
        }

        smallTitleUpper = {
            fontSize: "16px",
            color: 'var(--text-black)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
            textTransform: 'uppercase'
        }

        roundImage = "linear-gradient( 0deg, var(--background_4) 100%, var(--background_4) 100%)";
        roundLogo = gradientsC.Lion.Flex;
        mixMaxFlex = round.name.indexOf("Hockey") > -1 ? productNamesC.Orange.Hockey : productNamesC.Orange.Flex;
        number = round.name.indexOf("Hockey") > -1 ? "7" : "8";
        strokeColor = "none";
        borderRadius = "4px";
        borderRadiusTop = "4px 4px 0px 0px";
        borderImageSource = "none";
        widthBox = "300px";
        boxShadow = "0px 1px 3px 0px #00000040";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = productNamesC.Orange.Max;
            number = "13";
            roundLogo = gradientsC.Lion.Max;
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = productNamesC.Orange.Mix;
            number = "12";
            roundLogo = gradientsC.Lion.Mix;
        }
    }

    if (theme === themeC.Green) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: 'var(--text-black)',
            fontWeight: "400",
            fontFamily: 'var(--font-title)'
        };

        smallTitle = {
            fontSize: "16px",
            color: 'var(--text-black)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
        }

        smallTitleUpper = {
            fontSize: "16px",
            color: 'var(--text-black)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
            textTransform: 'uppercase'
        }        

        roundImage = "linear-gradient( 0deg, var(--background_4) 100%, var(--background_4) 100%)";
        roundLogo = gradientsC.Green.Flex;
        mixMaxFlex = round.name.indexOf("Hockey") > -1 ? productNamesC.Orange.Hockey : productNamesC.Orange.Flex;
        number = round.name.indexOf("Hockey") > -1 ? "7" : "8";
        strokeColor = "none";
        borderRadius = "4px";
        borderRadiusTop = "4px 4px 0px 0px";
        borderImageSource = "none";
        widthBox = "300px";
        boxShadow = "0px 1px 3px 0px #00000040";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = productNamesC.Orange.Max;
            number = "13";
            roundLogo = gradientsC.Green.Max;
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = productNamesC.Orange.Mix;
            number = "12";
            roundLogo = gradientsC.Green.Mix;
        }
    }

    if (theme === themeC.Dbet) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: "#5E5E5E",
            fontWeight: "600",
            fontFamily: 'var(--font-title)'
        };

        smallTitle = {
            fontSize: "16px",
            color: 'var(--font-title-color)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
        }

        smallTitleUpper = {
            fontSize: "16px",
            color: 'var(--font-title-color)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
            textTransform: 'uppercase'
        }

        roundImage = "linear-gradient( 0deg, var(--background_4) 100%, var(--background_4) 100%)";
        roundLogo = gradientsC.Dbet.Flex;
        mixMaxFlex = GetMixMaxFlex(round.name, window.theme);
        number = GetFlexNumber(round.name);
        strokeColor = "none";
        borderRadius = "10px";
        borderImageSource = "none";
        widthBox = "300px";
        minMaxFlexTextColor = "#F4C004";
        numberImage = "";
        numberBackgroundHeight = "61px";
        numberBackgroundWidth = "100px";
        topHeight = "120px";
        borderRadiusTop = "10px 10px 0px 0px";
        textTitle = "#5E5E5E";
        fontWeight = 600;
        fontWeightMoney = 900;
        fontFamily = "Righteous";
        numberIcon =
            <Grid container direction="row" alignItems="center" >
                <Grid sx={{ marginRight: "10px", paddingLeft: "25px" }}>
                    {DbetEightIcon()}
                </Grid>
                {DbetEightLadder()}
            </Grid>

        if (round.name.indexOf("Hockey") > -1) {
            numberIcon =
                <Grid container direction="row" alignItems="center" >
                    <Grid sx={{ marginRight: "7px", paddingLeft: "18px" }}>
                        {DbetSevenIcon()}
                    </Grid>
                    {DbetEightLadder()}
                </Grid>
        }

        if (round.name.indexOf("Max") > -1) {
            number = "13";
            roundLogo = gradientsC.Dbet.Max;
            numberBackgroundHeight = "64px";
            numberBackgroundWidth = "130px";
            numberIcon =
                <Grid container direction="row" alignItems="center" >
                    <Grid sx={{ marginRight: "10px", paddingLeft: "25px" }}>
                        {DbetThirteenIcon()}
                    </Grid>
                    {DbetThirteenLadder()}
                </Grid>
        }

        if (round.name.indexOf("Mix") > -1) {
            number = "12";
            roundLogo = gradientsC.Dbet.Mix;
            numberBackgroundHeight = "62px";
            numberBackgroundWidth = "130px";
            numberIcon =
                <Grid container direction="row" alignItems="center" >
                    <Grid sx={{ marginRight: "10px", paddingLeft: "25px" }}>
                        {DbetTwelveIcon()}
                    </Grid>
                    {DbetTwelveLadder()}
                </Grid>
        }

        numberImage = `url(${numberImage})`;
    }

    if (theme === themeC.Video) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: "#7E7E7E",
            fontWeight: "600",
            fontFamily: 'var(--font-title)'
        };

        smallTitle = {
            fontSize: "16px",
            color: 'var(--font-title-color)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
        }

        smallTitleUpper = {
            fontSize: "16px",
            color: 'var(--font-title-color)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
            textTransform: 'uppercase'
        }

        roundImage = "linear-gradient( 0deg, var(--background_4) 100%, var(--background_4) 100%)";
        roundLogo = `url(${video8})`;
        mixMaxFlex = GetMixMaxFlex(round.name, window.theme);
        strokeColor = "none";
        borderRadius = "10px";
        borderImageSource = "none";
        widthBox = "300px";
        minMaxFlexTextColor = "#7EFFB2";
        topHeight = "130px";
        borderRadiusTop = "10px 10px 0px 0px";
        textTitle = "#7E7E7E";
        fontWeight = 600;
        fontWeightMoney = 900;
        fontFamily = "Tilt Warp";

        if (round.name.indexOf("Max") > -1) {
            roundLogo = `url(${video13})`;
            minMaxFlexTextColor = "#FFF3B7";
        }

        if (round.name.indexOf("Mix") > -1) {
            roundLogo = `url(${video12})`;
            minMaxFlexTextColor = "#D47EDD";
        }
    }

    if (theme === themeC.MrVegas) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: "#8AD798",
            fontWeight: "400",
            fontFamily: 'var(--font-title)'
        };

        smallTitle = {
            fontSize: "16px",
            color: 'var(--font-title-color)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
        }

        smallTitleUpper = {
            fontSize: "16px",
            color: 'var(--font-title-color)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
            textTransform: 'none'
        }

        roundImage = "linear-gradient( 0deg, var(--background_4) 100%, var(--background_4) 100%)";
        roundLogo = `url(${mrvegas8})`;
        mixMaxFlex = "";
        strokeColor = "none";
        borderRadius = "10px";
        borderImageSource = "none";
        widthBox = "300px";
        minMaxFlexTextColor = "#7EFFB2";
        topHeight = "130px";
        borderRadiusTop = "10px 10px 0px 0px";
        textTitle = "#8AD798";
        fontWeight = 400;
        fontWeightMoney = 900;

        if (round.name.indexOf("Max") > -1) {
            roundLogo = `url(${mrvegas13})`;
        }

        if (round.name.indexOf("Mix") > -1) {
            roundLogo = `url(${mrvegas12})`;
        }
    }


    if (theme === themeC.Kung) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: "#78C4F8",
            fontWeight: "400",
            fontFamily: 'var(--font-title)'
        };

        smallTitle = {
            fontSize: "16px",
            color: 'var(--font-title-color)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
        }

        smallTitleUpper = {
            fontSize: "16px",
            color: 'var(--font-title-color)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
            textTransform: 'uppercase'
        }

        roundImage = "linear-gradient( 0deg, var(--background_4) 100%, var(--background_4) 100%)";
        roundLogo = gradientsC.Kung.Flex;
        mixMaxFlex = GetMixMaxFlex(round.name, window.theme);
        number = GetFlexNumber(round.name);
        strokeColor = "none";
        borderRadius = "10px";
        borderImageSource = "none";
        widthBox = "300px";
        minMaxFlexTextColor = "#27FFAA";
        numberImage = kung8;
        numberBackgroundHeight = "61px";
        numberBackgroundWidth = "46px";
        topHeight = "120px";
        borderRadiusTop = "10px 10px 0px 0px";
        textTitle = "#78C4F8";

        if (round.name.indexOf("Max") > -1) {
            number = "13";
            roundLogo = gradientsC.Kung.Max;
            minMaxFlexTextColor = "#34C2FF";
            numberImage = kung13;
            numberBackgroundHeight = "64px";
            numberBackgroundWidth = "77px";
        }

        if (round.name.indexOf("Mix") > -1) {
            number = "12";
            roundLogo = gradientsC.Kung.Mix;
            minMaxFlexTextColor = "#EE8DFF";
            numberImage = kung12;
            numberBackgroundHeight = "62px";
            numberBackgroundWidth = "77px";
        }

        numberImage = `url(${numberImage})`;
    }


    if (theme === themeC.Sultan) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: 'var(--text-black)',
            fontWeight: "400",
            fontFamily: 'var(--font-title)'
        };

        smallTitle = {
            fontSize: "16px",
            color: 'var(--text-black)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
        }

        smallTitleUpper = {
            fontSize: "16px",
            color: 'var(--text-black)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
            textTransform: 'uppercase'
        }

        roundImage = "linear-gradient( 0deg, var(--background_4) 100%, var(--background_4) 100%)";
        roundLogo = gradientsC.Sultan.Flex;
        mixMaxFlex = "Flex";
        number = "8";
        strokeColor = "none";
        borderRadius = "0px";
        borderImageSource = "none";
        widthBox = "300px";

        if (roundName.indexOf("Max") > -1) {
            mixMaxFlex = "Max";
            number = "13";
            roundLogo = gradientsC.Sultan.Max;
        }

        if (roundName.indexOf("Mix") > -1) {
            mixMaxFlex = "Mix";
            number = "12";
            roundLogo = gradientsC.Sultan.Mix;
        }
    }

    if (theme === themeC.Betconstruct || window.theme === themeC.Betconstructme || window.theme === themeC.Elit || window.theme === themeC.Metro
        || window.theme === themeC.Cwinz || window.theme === themeC.Betbeto
    ) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: 'var(--text-black)',
            fontWeight: "400",
            fontFamily: 'var(--font-title)'
        };

        smallTitle = {
            fontSize: "16px",
            color: 'var(--text-black)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
        }

        smallTitleUpper = {
            fontSize: "16px",
            color: 'var(--text-black)',
            lineHeight: "20px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
            textTransform: 'uppercase'
        }

        roundImage = "linear-gradient( 0deg, var(--background_4) 100%, var(--background_4) 100%)";
        roundLogo = gradientsC.Betconstruct.Flex;
        mixMaxFlex = t('flex');
        if (theme === themeC.Cwinz) {
            mixMaxFlex = productNamesC.Cwinz.Flex;
        }
        if (theme === themeC.Betbeto) {
            mixMaxFlex = productNamesC.Betbeto.Flex;
        }
        number = "8";
        strokeColor = "none";
        borderRadius = 'var(--radius)';
        borderImageSource = "none";
        widthBox = "300px";

        if (round.name.indexOf("Max") > -1) {
            mixMaxFlex = t('max');
            if (theme === themeC.Cwinz || theme === themeC.Betbeto) {
                mixMaxFlex = productNamesC.Cwinz.Max;
            }
            number = "13";
            roundLogo = gradientsC.Betconstruct.Max;
        }

        if (round.name.indexOf("Mix") > -1) {
            mixMaxFlex = t('mix');
            if (theme === themeC.Cwinz || theme === themeC.Betbeto) {
                mixMaxFlex = productNamesC.Cwinz.Mix;
            }
            number = "12";
            roundLogo = gradientsC.Betconstruct.Mix;
        }
    }

    if (theme === themeC.Gold) {

        smallText = {
            fontSize: "12px",
            lineHeight: "18px",
            color: 'var(--text-black)',
            fontWeight: "500",
            fontFamily: 'var(--font-title)',
            marginRight: "7px"
        };

        smallTitle = {
            fontSize: "12px",
            color: 'var(--text-black)',
            lineHeight: "18px",
            fontWeight: "700",
            fontFamily: 'var(--font-title)',
        }

        topHeight = "80px";

        roundImage = "linear-gradient( 0deg, var(--background_4) 100%, var(--background_4) 100%)";
        roundLogo = pridePick8;
        topLogo = pridePick8Logo;
        strokeColor = "none";
        borderRadius = "12px";
        borderImageSource = "none";
        border = "1.5px solid #454545";
        boxShadow = "none";
        boxPadding = "0px";

        if (roundName.indexOf("13") > -1) {
            roundLogo = pridePick13;
            topLogo = pridePick13Logo;
        }

        if (roundName.indexOf("12") > -1) {
            roundLogo = pridePick12;
            topLogo = pridePick12Logo;
        }

        roundLogo = `url(${roundLogo})`;
    }


    const start = new Date(round.openTo);
    const cutoff = new Date(Date.now());
    cutoff.setHours(cutoff.getHours() + 5);

    let showCountDown = start < cutoff;

    const reportSelection = () => {

        window.gtag("event", "select_content", {
            content_type: "round",
            content_id: "round_box"
        });
    }

    return (

        <div onClick={() => reportSelection()}>
            <Grid sx={{
                width: widthBox,
                border: border,
                borderRadius: borderRadius,
                backgroundImage: borderImageSource,
                backgroundOrigin: "border-box",
                backgroundClip: "padding-box, border-box",
                padding: boxPadding,
                margin: "7px"
            }}>
                <Grid container alignItems="flex-start" direction="column" sx={{
                    margin: '0px', borderRadius: borderRadius,
                    backgroundImage: roundImage, backgroundSize: 'cover',
                    boxShadow: boxShadow
                }}>
                    <Grid container justifyContent="center" sx={{ marginTop: "0px", marginBottom: "11px" }}>
                        <Grid item sx={{
                            width: "100%",
                            height: topHeight,
                            backgroundImage: roundLogo,
                            backgroundSize: 'cover',
                            borderRadius: borderRadiusTop
                        }}>
                            {(window.theme === themeC.Medina || window.theme === themeC.MedinaAr) &&
                                <Grid direction="column" container alignItems="center" sx={{ marginTop: "0px", marginBottom: "11px" }}>
                                    <Typography sx={{
                                        fontSize: "25px",
                                        fontFamily: "monumentextended",
                                        color: 'var(--text-black)',
                                        fontWeight: "400",
                                        textTransform: "uppercase",
                                        marginTop: "20px"
                                    }}>
                                        {mixMaxFlex}
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: "80px",
                                        fontFamily: "monumentextended",
                                        background: strokeColor,
                                        backgroundClip: "text",
                                        color: "transparent",
                                        display: "inline-block",
                                        fontWeight: "800",
                                        marginTop: "-25px"
                                    }}>
                                        {number}
                                    </Typography>
                                </Grid>
                            }
                            {(window.theme === themeC.Blue2 || window.theme === themeC.Sultan || IsLeo(window.theme)) &&
                                <Grid direction="column" container alignItems="center" sx={{ marginTop: "-5px", marginBottom: "11px" }}>
                                    <Typography sx={{
                                        fontSize: "25px",
                                        fontFamily: 'var(--font-title)',
                                        color: 'var(--text-white)',
                                        fontWeight: "400",
                                        textTransform: "uppercase",
                                        marginTop: "20px"
                                    }}>
                                        {mixMaxFlex}
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: "80px",
                                        fontFamily: 'var(--font-title)',
                                        color: 'var(--text-white)',
                                        fontWeight: "800",
                                        marginTop: "-25px"
                                    }}>
                                        {number}
                                    </Typography>
                                </Grid>
                            }
                            {IsVideo(window.theme) &&
                                <Grid direction="column" container alignItems="center" sx={{ marginTop: "-5px", marginBottom: "11px" }}>
                                    <Typography sx={{
                                        fontSize: "20px",
                                        fontFamily: fontFamily,
                                        color: minMaxFlexTextColor,
                                        fontWeight: (window.theme === themeC.Dbet || window.theme === themeC.Video) ? "400" : "700",
                                        textTransform: (window.theme === themeC.Video) ? "none" : "uppercase",
                                        marginTop: "10px",
                                        marginBottom: "5px"
                                    }}>
                                        {mixMaxFlex}
                                    </Typography>
                                    <Box
                                        sx={{
                                            backgroundImage: numberImage,
                                            backgroundSize: "contain",
                                            backgroundRepeat: "no-repeat",
                                            height: numberBackgroundHeight,
                                            width: numberBackgroundWidth,
                                        }}
                                    >
                                        {numberIcon}
                                    </Box>

                                </Grid>
                            }

                            {(window.theme === themeC.Betconstruct || window.theme === themeC.Betconstructme || window.theme === themeC.Elit || window.theme === themeC.Metro
                                || window.theme === themeC.Cwinz || window.theme === themeC.Betbeto
                            ) &&
                                <Grid direction="column" container alignItems="center"
                                    sx={{
                                        marginTop: "-5px",
                                        marginBottom: "11px",
                                    }}>
                                    <Typography sx={{
                                        fontSize: "25px",
                                        fontFamily: 'var(--font-title)',
                                        color: 'var(--text-white)',
                                        fontWeight: "400",
                                        textTransform: "uppercase",
                                        marginTop: "20px"
                                    }}>
                                        {mixMaxFlex}
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: "80px",
                                        fontFamily: 'var(--font-title)',
                                        color: 'var(--text-white)',
                                        fontWeight: "800",
                                        marginTop: "-25px"
                                    }}>
                                        {number}
                                    </Typography>

                                </Grid>
                            }

                            {window.theme === themeC.Gold &&
                                <Grid container justifyContent="center"
                                    sx={{
                                        minwidth: "100%",
                                        marginTop: "5px"
                                    }}>
                                    <Grid item
                                        sx={{
                                            height: "70px",
                                            width: "220px",
                                            backgroundImage: `url(${topLogo})`,
                                            backgroundSize: "contain",
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat'
                                        }}>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                    {window.theme !== themeC.Gold && jackpot &&
                        <Grid container alignItems="center" direction="column" sx={{ marginLeft: "0px" }}>
                            <Typography sx={{
                                fontSize: "14px",
                                color: textTitle,
                                fontWeight: fontWeight,
                                fontFamily: window.theme === themeC.Wood ? 'var(--font-body)' : 'var(--font-title)',
                                marginTop: "10px"
                            }}>{t('jackpot')}</Typography>
                            <Typography sx={{
                                fontSize: "24px",
                                color: 'var(--text-black)',
                                lineHeight: "57px",
                                fontWeight: fontWeightMoney,
                                fontFamily: 'var(--font-title)',
                                marginTop: "-10px"
                            }}>
                                {formatStringCurrency(jackpot, turnoverCurrency, t, true)}
                            </Typography>

                        </Grid>
                    }

                    {window.theme !== themeC.Gold && window.theme !== themeC.Medina && window.theme !== themeC.MedinaAr &&
                        <Grid container direction="column" justifyContent="space-evenly"
                            sx={{ marginTop: "5px", marginBottom: "11px" }}>
                            <Grid item>
                                <Grid container alignItems="center" direction="column">
                                    <Typography sx={smallText}>{t('turnover')}</Typography>
                                    <Typography sx={smallTitle}>{formatStringCurrency(turnoverAmount, turnoverCurrency, t, true)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ marginTop: "10px" }}>
                                <Grid container alignItems="center" direction="column">
                                    <Typography sx={smallText}>{t('starts')}</Typography>
                                    {
                                        showCountDown ?
                                            <CountdownTimer width="68px" fontSize="16px" targetTime={new Date(round.openTo)} /> :
                                            <Typography sx={smallTitleUpper}>{dateString}</Typography>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>
                    }

                    {(window.theme === themeC.Medina || window.theme === themeC.MedinaAr) &&
                        <Grid container direction="row" justifyContent="space-evenly"
                            sx={{ marginTop: "5px", marginBottom: "11px" }}>
                            <Grid item>
                                <Grid container alignItems="center" direction="column">
                                    <Typography sx={smallText}>{t('turnover')}</Typography>
                                    <Typography sx={smallTitle}>{formatStringCurrency(turnoverAmount, turnoverCurrency, t, true)}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center" direction="column">
                                    <Typography sx={smallText}>{t('starts')}</Typography>
                                    {
                                        showCountDown ?
                                            <CountdownTimer width="68px" fontSize="16px" targetTime={new Date(round.openTo)} /> :
                                            <Typography sx={smallTitleUpper}>{dateString}</Typography>
                                    }
                                </Grid>
                            </Grid>

                        </Grid>
                    }

                    {window.theme === themeC.Gold &&
                        <>
                            <Grid container direction="row" justifyContent="space-between">
                                <Grid item>
                                    <Grid container alignItems="flex-start" direction="column" sx={{ marginLeft: "10px" }}>
                                        <Typography sx={{
                                            fontSize: "12px",
                                            color: 'var(--text-black)',
                                            fontWeight: "500",
                                            fontFamily: 'var(--font-title)',
                                            marginTop: "-5px"
                                        }}>{roundName} - {t('jackpot')}</Typography>
                                        <Typography sx={{
                                            fontSize: "24px",
                                            color: 'var(--text-black)',
                                            lineHeight: "36px",
                                            fontWeight: "800",
                                            fontFamily: 'var(--font-title)',
                                        }}>
                                            {formatStringCurrency(jackpot, turnoverCurrency, t, true)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{ paddingTop: "25px", marginRight: "5px" }}>
                                    <Grid container alignItems="space-between" direction="row" >
                                        <Typography paddingRight="5px" sx={smallTitle}>1X2</Typography>
                                        <Typography paddingRight="5px" sx={smallTitle}>|</Typography>
                                        <Typography sx={smallText}>GHS 1.25</Typography>
                                    </Grid>
                                </Grid>

                            </Grid>

                            <Grid container direction="row" justifyContent="space-between"
                                sx={{ marginLeft: "10px", width: "316px" }}>
                                <Grid item>
                                    <Grid container alignItems="space-between" direction="row">
                                        <Typography sx={smallText}>{t('turnover')}</Typography>
                                        <Typography sx={smallTitle}>{formatStringCurrency(turnoverAmount, turnoverCurrency, t, true)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container alignItems="space-between" direction="row">
                                        <Typography sx={smallText}>{t('starts')}</Typography>
                                        {
                                            showCountDown ?
                                                <CountdownTimer width="53px" fontSize="12px" targetTime={new Date(round.openTo)} /> :
                                                <Typography sx={smallTitle}>{dateString}</Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    }

                    <Grid container direction="row" justifyContent="space-evenly" sx={{ marginTop: "5px", marginBottom: "11px" }}>
                        <Grid item>
                            <Link to={link} >
                                <div onClick={() => reportSelection()}>
                                    {theme !== themeC.Gold && theme !== themeC.Dbet && !IsLeo(theme) &&
                                        <Button>{t('show_round')}</Button>
                                    }
                                    {theme === themeC.Dbet &&
                                        <Button padding="8px 35px">
                                            <Grid container direction="row" alignItems="center">
                                                <Typography sx={{ marginRight: "10px", fontWeight: "700" }}>{t('show_round')}</Typography>
                                                <Grid>
                                                    <Grid container direction="column" justifyContent="center" height="100%">
                                                        {DbetArrowRight}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Button>
                                    }
                                    {theme === themeC.Gold &&
                                        <Button width="320" textColor="#FFFFFF" color="#4CB34A">{t('show_round')}</Button>
                                    }
                                    {(IsLeo(theme)) &&
                                        <Button >{t('show_round')}</Button>
                                    }
                                </div>
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </div>


    );
}

export default RoundBoxWithButton;