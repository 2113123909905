import { Avatar } from "@mui/material";

function SyndicateAvatar({ agentName, agentId, objectUrl }: { agentName: string, agentId: number, objectUrl: string }) {

    if (objectUrl !== null) {
        return (
            <Avatar alt={agentName} src={objectUrl} sx={{ margin: "10px 10px 10px 0px" }} />
        )
    }
    return (
        <Avatar sx={{ margin: "10px 10px 10px 0px", bgcolor: "blue" }} >{agentName[0]}</Avatar>
    )
}

export default SyndicateAvatar