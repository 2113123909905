import { usePlayer } from "../player/playerContext";
import { QueryResult, useQuery, useQueryCache } from "react-query";
import fetchApi from "../backend/fetchApi";
import * as types from './types';
import { ShallowRoundResponse } from "./types";

const useShallowRounds = (showActive: boolean, showSettled: boolean, page: number = 0) => {
    const [player] = usePlayer() as any;

    const queryCache = useQueryCache();
    let roundsUrl = player && player._links ? player._links.rounds.href : '';
    roundsUrl += '?shallow=true&showActive=' + showActive.toString() + '&showSettled=' + showSettled.toString();
    roundsUrl += '&size=30&page=' + page;
    const key = 'shallowRounds' + showActive.toString() + showSettled.toString() + page;
    let rounds = useQuery<types.ShallowRoundResponse, unknown>(key, () => {
        return fetchApi(roundsUrl)
            .then(response => response.json()).then((responseJson) => {
                return { rounds: responseJson._embedded.rounds, page: responseJson.page, totalPages: responseJson.totalPages }
            })
    }, {
        initialStale: true,
        enabled: !!player, // if the player is not available yet, the query won't execute
    })

    return [rounds.data, {
        ...rounds,
        reloadRounds: () => queryCache.invalidateQueries(key),
    }] as [ShallowRoundResponse | undefined, QueryResult<ShallowRoundResponse> & {
        reloadRounds: () => Promise<any>
    }]
}

export default useShallowRounds;