import { H2 } from "../reusable-components";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Grid, Typography, useMediaQuery } from "@mui/material";
import { SyndicatePurchase } from "../models/syndicate";
import { ShallowRound } from "../rounds/types";
import SyndicateAvatar from "./syndicateavatar";
import SyndicateListItem from "./syndicatelistitem";
import useAgents from "./useAgents";
import { useEffect, useState } from "react";
import fetchApi from "../backend/fetchApi";
import { Agent } from "../models/agent";

const SyndicateList = ({ syndicates, shallowRounds, agentPage, theme }:
  { syndicates: SyndicatePurchase[], shallowRounds: ShallowRound[], agentPage: boolean, theme: string }) => {

  const { t } = useTranslation();
  const agents = useAgents();
  const isSmallScreen = useMediaQuery('(max-width: 620px)');
  const [avatars, setAvatars] = useState<JSX.Element[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchImage = async (imageUrl: string) => {
    try {
      const response = await fetchApi(imageUrl);
      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);
      return objectUrl;

    } catch (error) {
      console.error("Error fetching image:", error);
    }
  };

  useEffect(() => {
    async function fetchAvatars() {
      // Preload images
      if (!agents) {
        return;
      }
      await Promise.all(
        agents.map(async (agent: Agent) => {
          let imageUrl = agent._links?.avatarImage?.href;
          if (imageUrl === undefined) {
            return undefined;
          }
          let objectUrl = await fetchImage(agent._links?.avatarImage?.href);
          agent.objectUrl = objectUrl;
          return objectUrl;
        })
      );

      // Create avatar components after preloading images
      const avatarElements = agents.map((agent: any) => (
        <SyndicateAvatar
          key={agent.id}
          agentName={agent.name}
          agentId={agent.id}
          objectUrl={agent.objectUrl}
        />
      ));

      setAvatars(avatarElements);
      setLoading(false);
    }

    if (agents) {
      fetchAvatars();
    }

  }, [agents]);


  let perAgent: any = {};

  if (syndicates !== undefined) {
    syndicates.forEach(element => {
      if (typeof perAgent[element.agentName] !== 'undefined') {
        perAgent[element.agentName].push(element);
      } else {
        var list = [];
        list.push(element);
        perAgent[element.agentName] = list;
      }
    });
  }

  for (let index = 0; index < syndicates.length; index++) {

    const syndicate = syndicates[index];

    if (agents) {
      let agent = agents.filter(a => a.id === syndicate.agentId)[0];
      syndicate.ordNo = agent.ordNo;
    }

  }

  let perAgentList: Array<SyndicatePurchase[]> = [];

  Object.keys(perAgent).forEach(function (key, index) {
    let list: SyndicatePurchase[] = perAgent[key];
    list = list.sort((a, b) => {

      let roundA = shallowRounds.find(r => r.id === a.roundId.toString());
      let roundB = shallowRounds.find(r => r.id === b.roundId.toString());

      if (roundA === undefined || roundB === undefined)
        return 0;

      if (roundA.openTo > roundB.openTo)
        return 1;

      return -1;
    }
    )

    list = list.sort((a, b) => {
      const diff1 = a.targetNShares - a.nShares;
      const diff2 = b.targetNShares - b.nShares;

      // Objects where a - b = 0 should come last
      if (diff1 === 0 && diff2 !== 0) return 1;
      if (diff2 === 0 && diff1 !== 0) return -1;
      return 0; // Keep relative order for the rest
    })

    perAgentList.push(list);
  });

  perAgentList.sort((a, b) => {
    // Compare the `OrdNo` of the first element in each array
    return a[0].ordNo - b[0].ordNo;
  });

  if (loading) {
    return <></>
  }

  return (

    <Grid container direction="column" sx={{
      background: 'var(--background_1)',
      padding: isSmallScreen ? "10px" : "40px",
      alignItems: "center"
    }}>

      {perAgentList && perAgentList.map((syndicatesForAgent, i) => {

        let realSyndicates = 0;
        let agent = agents?.filter(a => a.id === syndicatesForAgent[0].agentId)[0];
        let avatar = avatars.filter(a => a.props["agentId"] === syndicatesForAgent[0].agentId);

        let syndicateList = syndicatesForAgent.map((syndicate, j) => {

          let round = shallowRounds.filter(r => r.id === syndicate.roundId.toString());

          if (syndicate.agentName.toLowerCase() === 'anders svensson' && syndicate.nShares === syndicate.targetNShares) {
            return <div key={"empty" + j} />;
          }

          if (j > 3 && !agentPage) {
            if (round.length === 1 && round[0].operatorTurnover.currency === syndicate.stakePerShare.currency) {
              realSyndicates++;
            }
            return <div key={"empty" + j} />;
          }

          if (round.length === 1 && round[0].operatorTurnover.currency === syndicate.stakePerShare.currency) {
            realSyndicates++;
            return (
              <SyndicateListItem key={'syndicate' + j} syndicate={syndicate} round={round[0]} theme={theme} />
            )
          }
          return <div key={"empty" + j} />;
        });

        if (realSyndicates === 0) {
          return <div key={"none" + i} ></div>;
        }

        return (

          <Grid key={agent?.name + i.toString()} sx={{
            padding: isSmallScreen ? "0px" : "20px",
            width: "100%",
            marginBottom: "40px",
            background: isSmallScreen ? 'inherit' : "var(--syndicate-list-background)"
          }}>
            <Grid sx={{ marginBottom: "20px" }}>
              <Grid item sx={{ marginBottom: "20px" }}>
                <Grid container direction="row" alignItems="center">
                  {avatar && avatar}
                  <Grid item sx={{ marginLeft: isSmallScreen ? "0px" : "10px", paddingTop: "5px" }}>
                    <Grid container direction="column" justifyContent="center">
                      <H2 key='title' sx={{ marginBottom: '10px' }} >{agent?.name ?? ""}</H2>
                      <Typography sx={{ color: 'var(--text-black)' }}>{realSyndicates} {t('active_syndicates')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {!agentPage && <Link to={'/syndicatesforagent/' + agent?.id}><span>{t('to_agent')}</span></Link>}
              {agentPage && <Link to='/playagent'><span>{t('go_back')}</span></Link>}
            </Grid>
            <Grid container direction="row" justifyContent="center" sx={{ flexWrap: "wrap", marginBottom: "10px" }}>
              {syndicateList}
            </Grid>
          </Grid>
        )
      }
      )}

    </Grid >
  );
}

export default SyndicateList;