import {usePlayer} from "../player/playerContext";
import {useQuery} from "react-query";
import fetchApi from "../backend/fetchApi";
import { Agent } from "../models/agent";

const useAgents = () => {
  const [player] = usePlayer()as any;
  const agentsUrl = player && player._links.agents.href;
  const agents = useQuery<Agent[],unknown>(['agents', agentsUrl], () => {
    return fetchApi(agentsUrl).then(response => response.json()).then(r => r._embedded.agents)
  }, {
    enabled: !!player,
  })

  return agents.data;
}

export default useAgents;