import { Grid, SxProps, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from "react-i18next";
import { SlimCouponRowStyles, SlimCouponStyles } from './slimfixturedetail';
import { FixtureDetail, Standing } from '../models/fixturedetail';
import { Fixture } from '../models/fixture';

const StandingsTableRow = ({ standing, even, inFixture }:
    { standing: Standing, even: boolean, inFixture: boolean }) => {
    return (
        <TableRow sx={even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd}>
            <TableCell sx={even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd}>
                {standing.position}
            </TableCell>

            <TableCell sx={inFixture ? { ...even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd, ...{ fontWeight: "bold" } }
                : even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd}>
                {standing.team_name}
            </TableCell>

            <TableCell sx={even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd}>
                {standing.overall.games_played}
            </TableCell>

            <TableCell sx={even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd}>
                {standing.overall.games_won}
            </TableCell>

            <TableCell sx={even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd}>
                {standing.overall.games_draw}
            </TableCell>

            <TableCell sx={even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd}>
                {standing.overall.games_lost}
            </TableCell>

            <TableCell sx={even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd}>
                {standing.goal_difference}
            </TableCell>

            <TableCell sx={even ? SlimCouponRowStyles.rowEven : SlimCouponRowStyles.rowOdd}>
                {standing.points}
            </TableCell>
        </TableRow>
    );
}


const renderHeader = (t: any) => {
    return (
        <TableRow sx={SlimCouponRowStyles.rowEven}>
            <TableCell sx={SlimCouponStyles.infoTextStyle}></TableCell>
            <TableCell sx={SlimCouponStyles.infoTextStyle}>{t('team_name')}</TableCell>
            <TableCell sx={SlimCouponStyles.infoTextStyle}>{t('table_abbreviation_num_games')}</TableCell>
            <TableCell sx={SlimCouponStyles.infoTextStyle}>{t('table_abbreviation_wins')}</TableCell>
            <TableCell sx={SlimCouponStyles.infoTextStyle}>{t('table_abbreviation_draws')}</TableCell>
            <TableCell sx={SlimCouponStyles.infoTextStyle}>{t('table_abbreviation_losses')}</TableCell>
            <TableCell sx={SlimCouponStyles.infoTextStyle}>+/-</TableCell>
            <TableCell sx={SlimCouponStyles.infoTextStyle}>{t('table_abbreviation_points')}</TableCell>
        </TableRow>
    );
}

const renderRows = (standings: Standing[], fixture: Fixture, t:any) => {
    const result = [];
    for (let i = 0; i < standings.length; i++) {
        const even = (i % 2 === 0);
        const inFixture = standings[i].team_name === fixture.homeTeam.name ||
            standings[i].team_name === fixture.awayTeam.name;

        if(fixture.homeTeam.isNationalTeam){
            standings[i].team_name = t('teams:' + standings[i].team_name);
        }

        result[i] = <StandingsTableRow key={'name' + i} even={even} standing={standings[i]} inFixture={inFixture} />
    }

    return result;
}

function LeagueTable({ fixtureDetails }: { fixtureDetails: FixtureDetail }) {
    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery('(max-width: 950px)');
    const { standings } = fixtureDetails;

    if (!standings) {
        return <></>
    }

    let listStyle: SxProps<Theme> = {
    };
  
    if (!isSmallScreen) {
      listStyle = {
        ...listStyle, ...{
          maxWidth: "400px",
          marginBottom: "100px",
          marginTop: "20px"
        }
      };
    }

    return (
        <Grid container direction="column" alignItems="center" sx={listStyle}>
            <Grid container sx={{marginBottom:"10px"}}>
                <Typography sx={SlimCouponStyles.teamStyle}>{fixtureDetails.league.name}</Typography>
            </Grid>
            <Table>
                <TableHead>
                    {renderHeader(t)}
                </TableHead>
                <TableBody>
                    {renderRows(standings, fixtureDetails.fixture, t)}
                </TableBody>
            </Table>
        </Grid>
    );
}


export default LeagueTable;