export const TRANSLATIONS_FI = {
    max: "Max",
    mix: "Mix",
    flex: "Flex",
    
    my_bets: "Vedot",
    my_coupons: "Kupongit",
    rounds: "Kierrokset",    
    bet_with_syndicates: "Pelaa agenttien kanssa",
    
    syndicates: "Välityspalvelu",
    turnover: "Nykyinen potti",
    
    single_rows: "Yksittäiset rivit",
    pro_pick: "Pro pick",
    often: "Voittaa useimmin",
    a_lot: "Voittaa enemmän",
    system: "Järjestelmä",
    row_distribution: "Rivien jakautuminen",
    overview: "Yhteenveto",
    full_cover: "Full cover",
    half_cover: "Half cover",
    row_price: "Rivin hinta",
    pay: "Maksa",
    
    unsettled_coupons: "Nykyiset kupongit",
    settled_coupons: "Pelatut kupongit",
    stake: "Panos",
    coupon_share_status_cancelled: "Peruttu",
    your_winnings_right_now: "Tämänhetkiset voittosi",
    correct_rows_now: "Tämänhetkiset oikeat rivit",
    starts: "Alkaa",
    correct_rows: "Oikeat rivit",
    prize: "Palkinto",
    your_rows: "Sinun rivit",
    profit: "Voitto",
    st: "",
    total: "Tulos",
    rows: "riviä",
    row: "rivi",
    price_per_row: "Rivin hinta",
    total_cost: "Hinta yhteensä",
    paid: "Maksettu",
    settled: "Ratkaistu",
    channel_internet: "Kanava: Internet",
    print: "Tulosta",
    share: "Jaa",    
    clear_bets: "Tyhjennä vedot",
    
    
    reduce: "Vähennä",
    no_reduced_systems_available: "No reduced systems available",
    round_history: "Tulokset",
    
    no_profit: "Ei voittoa",
    no_coupons: "Ei kuponkeja",
    started: "Alkoi",
    round: "Kierros",
    winners: "Voittajat",
    your_bet_is_paid: "Vetosi on maksettu!",
    do_you_want_to_place_bet: "Haluatko asettaa vedon",
    
    highest_win: "Suurin voitto",
    insufficient_funds: "Riittämättömät varat",
    cancel: "Peruuta",
    unable_to_complete_purchase: 'Ostosta ei voida suorittaa',
    error_code: 'Virhe',

    event_goal: 'Maali',
    event_penalty: 'Rangaistus',
    event_missed_penalty: 'Ei maalia rangaistuspotuksta',
    event_own_goal: 'Oma maali',
    event_var: 'VAR',
    event_yellowcard: 'Keltainen kortti',
    event_yellowred: 'Toinen keltainen kortti',
    event_substitution: 'Korvaava pelaaja',
    event_redcard: 'Punainen kortti',
    event_pen_shootout_goal: 'Maali rangaistuspotkusta',
    event_pen_shootout_miss: 'Ei maalia rangaistuspotuksta',
    event_full_time: 'Täysiaika',
    event_half_time: 'Puoliaika',
    event_kickoff: 'Aloituspotku',
    event_finished_after_extra: 'Jatkoaika päättynyt',

    out: 'Ulkona',

    
    table: "Taulukko",
    game_info: "Ottelutiedot",
    statistics: "Tilastot",
    
    
    team_name: "Joukkue",
    table_abbreviation_num_games: "O",
    table_abbreviation_wins: "V",
    table_abbreviation_draws: "T",
    table_abbreviation_losses: "H",
    table_abbreviation_points: "P",

    round_active_after_deadline: 'Suljettu',
    full_time: "Täysi aika",
    round_status_finished : "Päättynyt",
    half_time: 'Puoliaika',
    

    current_rounds: "Tämänhetkiset kierrokset",
    settled_rounds: "Ratkaistut kierrokset",
    no_rounds_available: "Ei kierroksia saatavana",

    row_statistics: 'Rivin tilastot',
    
    showing: 'Näytetään',    
    settings: 'Asetukset',
    match: 'Ottelu',
    result: 'Tulos',    
    bet_type: "Vetotyyppi",    
    mathematical_system: "Matemaattinen järjestelmä",
    reduced_system: "Supistettu järjestelmä",
    no_system: "Ei järjestelmää",
    select: "Valitse",
    is_draw: "Tulos arvotaan todennäköisyyden mukaan prosenteilla",
    is_drawn: "Arvonta",
    draw_disclaimer: 'Jos ottelua ei pelata:',
    num_correct_win: 'Oikeiden/voittojen määrä',
    more_info: 'Lisää tietoa',
    in_running: 'Käynnissä',

   
    go_back: 'Palaa',

    to_syndicate: "Välityspalveluun",
    shares: "Osuudet",
    share_price: "Osuuden hinta",
    sold_shares: "Myydyt osuudet",
    agent_admin: "Agentin järjestelmänvalvoja",
    agents: "Agentti",
    to_agent: "Agentille",
    create_new_syndicate: "Luo uusi välityspalvelu",
    name: "Nimi",
    target_number_of_shares: "Osuuksien tavoitemäärä",
    max_number_of_shares: "Osuuksien maksimimäärä",
    submit_syndicate: "Lisää välityspalvelu",
    update_syndicate: "Lataa välityspalvelu",
    show_sv_percent: 'Näytä porukan pelaaja %',
    show_coupon_percent: 'Näytä kupongin %',
    number_of_rows: 'Rivien määrä',
    share_of_syndicate: 'Välityspalvelun osuus',
    syndicate_created: 'Välityspalvelinpeli luotu',
    syndicate_submitted: 'Välityspalvelinpeli lisätty',
    syndicate_updated: 'Välityspalvelinpeli päivitetty',
    finished: 'Päättyneet',
    started_games: 'Alkaneet',
    potential: 'Mahdollinen',
    sort_by_finished: 'Lajittele päättyneiden mukaan',
    game_summary: 'Ottelun yhteenveto',
    date: 'Päivämäärä',
    league: 'Liiga',
    venue: 'Areena',
    latest_tweets: 'Uusimmat twiitit',
    weather: 'Sää',
    temperature: 'Lämpotila',
    wind: 'Tuuli',
    moisture: 'Ilmankosteus',
    general_info: 'Yleistä tietoa',
    locale: 'fi-FI',
    show: 'Näytä',
    show_round: 'Näytä kierros',
    play_on: 'Pelaa',
    today: 'Tänään',
    latest_games: 'Viimeisimmät pelit',
    head_to_head: 'Vertaile',
    round_information_short: 'Tietoa',
    round_information: 'Kierroksen tiedot',
    prize_groups: 'Palkintoryhmät',
    bet: 'Veto',
    pro_pick_bets: 'Pro Pick vedot',
    help: 'Ohje',
    round_status_open : "Avoinna",
    round_status_voided : "Peruutettu",
    round_status_paused : "Keskeytetty",
    round_paused_explanation: 'Kierroksen vedonlyönti on väliaikaisesti keskeytetty',
    jackpot : "Arvioitu jackpot",
    after_extra_time: "Jatkoajan jälkeen",
    system_guarantee: "Takuu",
    reduced_disclaimer:"Supistetussa järjestelmässä vastaavan matemaattisen järjestelmän rivien määrää on vähennetty. Saat pienemmän mahdollisuuden voittaa, mutta järjestelmä maksaa myös vähemmän.",
    show_rows:"Näytä rivini",
    api_betting:"Lataa tiedosto",
    select_file:"Valitse tiedosto",
    file:"Tiedosto",
    id:"Id",
    upload:"Lataa",
    bet_title:"Veto",
    follow_title:"Seuraa",
    win_title:"Voita",
    bet_text:"Valitse yksi tai useampi lopputulos kierroksen jokaisesta jalkapallo-ottelusta",
    follow_text:"Seuraa voittojasi livenä",
    win_text:"Mikäli olet veikannut vaaditun määrän oikeita lopputuloksia olet yksi pelaajaporukan voittajista",
    share_of_prizepool: "Osuus palkintopotista",
    register: "Rekisteröidy",
    fee_per_row: "Fee per row",
    bet_status_open: "Open",
    bet_status_won: "Won",
    bet_status_lost: "Lost",
    payout: "Payout",
    show_details: "Show details",
    bet_now: "Bet now",
    place_more: "If you want to increase your chances of winning you can pick more than one outcome in each game",
    active_syndicates: "active syndicates",
    settled_syndicates: "settled syndicates",
    win_multiplier: "Voiton kerroin",
    win_multiplier_information: "Panoksesi ja mahdollinen voitto kerrotaan valitulla voittokertoimella",
    status: "Tila",
    balance: "Balance",

    join_title:"Join the group",
    join_text:"Take part in a bigger system together with other players and increase your chances of winning the jackpot.",
    valid_title:"Always valid",
    valid_text:"Your bet is always valid, even if the syndicate is not sold out.",
    win_together_title:"Win together",
    win_together_text:"The winnings from the syndicate are split between the shares.",
    fee:"Fee",
    error_title:"Something went wrong",
    error_text:"Please try refreshing the page, or go back to the homepage.",
    reload:"Refresh",
    odds:"Odds",
    login:"Log in",
    syndicate_not_finalised:"Syndicate not finalised",
}
