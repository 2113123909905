// Note: Navigation bar component. Some themes uses navbarlight.js insted of this file.

import { Link } from 'react-router-dom';
import { PoolxLogoNavbar, LilibetLogoNavbar, HouseIconNavbar } from '../reusable-components';
import { useTranslation } from 'react-i18next';
import { usePlayer } from "../player/playerContext";
import PlayerBalance from '../playerbalance/PlayerBalance';
import NavbarLight from './navbarlight';
import { IsLeo, IsMiddleEast, themeC } from '../theme/themehelper';
import usePlayerBalance from '../playerbalance/usePlayerBalance';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import MobileNavBar from './mobilenavbar';
import MobileNavBarOrange from './mobilenavbarorange';


const NavBar = ({ whichToUnderline, balanceUpdateId }) => {
  const { t } = useTranslation();
  const [player, config] = usePlayer();
  let [balance, loadingBalance] = usePlayerBalance(balanceUpdateId);
  const agentSyndicateBetting = config && config.agentSyndicateBetting;
  const realMode = player && player._embedded && player._embedded.mode === 'real';
  const hideBalance = config && !config.showPlayerBalance;
  
  let theme = window.theme;
  const isMobileScreen = useMediaQuery('(max-width: 950px)');

  const reportSelection = (type, id) => {

    window.gtag("event", "select_content", {
      content_type: type,
      content_id: id
    });

  }

  if (theme === themeC.Gold) {
    return <NavbarLight whichToUnderline={whichToUnderline} />
  }

  let styleTop = {
    backgroundColor: 'var(--title-bar-background)',
    fontFamily: 'var(--font-title-3)',
    height: 'var(--navbar-height)',
    paddingLeft: 'var(--coupon-left-margin)',
    flexDirection: 'var(--flex-direction)',
  }

  let linkStyle = {
    fontFamily: 'var(--font-title-3)',
    marginRight: "20px",
    color: "var(--title-bar-color)",
    textDecoration: "none",
    fontWeight: "bold",
    fontSize: "14px",
    maxWidth: "100px",
    textWrap: "nowrap"
  }

  let underlined = {
    fontFamily: 'var(--font-title-3)',
    marginRight: "20px",
    color: "var(--title-bar-color)",
    textDecoration: "underlined",
    fontWeight: "bold",
    fontSize: "14px",
    maxWidth: "100px",
    textWrap: "nowrap"
  }

  let homeIcon = PoolxLogoNavbar;

  switch (theme) {
    case themeC.Blue:
    case themeC.Blue2:
      homeIcon = LilibetLogoNavbar;
      break;
    case themeC.Kung:
      homeIcon = <Typography sx=
        {
          {
            fontWeight: 900,
            fontStyle: "italic",
            fontSize: "16px",
            lineHeight: "18.75px",
            paddingRight: "5px"
          }}>Supertipset</Typography>
      break;
    case themeC.Dbet:
      homeIcon = <Typography sx=
        {
          {
            fontWeight: 900,
            fontStyle: "italic",
            fontSize: "16px",
            lineHeight: "18.75px",
            color: "#F4C004",
            paddingRight: "5px"
          }}>Supertipset</Typography>
      break;
      case themeC.Video:
      homeIcon = <Typography sx=
        {
          {
            fontWeight: 900,
            fontStyle: "italic",
            fontSize: "16px",
            lineHeight: "18.75px",
            color: "#F4C004",
            paddingRight: "5px"
          }}>Supertipset</Typography>
      break;
      case themeC.MrVegas:
      homeIcon = <Typography sx=
        {
          {
            fontWeight: 900,
            fontStyle: "italic",
            fontSize: "16px",
            lineHeight: "18.75px",
            color: "#FFFFFF",
            paddingRight: "5px"
          }}>Supertipset</Typography>
      break;
    default:
      break;
  }

  if (IsMiddleEast(theme)) {
    homeIcon = HouseIconNavbar;
  }

  if (window.operatorId === 'LILIBET') {
    homeIcon = HouseIconNavbar;
  }

  if (IsLeo(theme)) {
    return <MobileNavBarOrange theme={theme} realMode={realMode} agentSyndicateBetting={agentSyndicateBetting} reportSelection={reportSelection} whichToUnderline={whichToUnderline} />;
  }
  if (isMobileScreen) {
    return <MobileNavBar theme={theme} realMode={realMode} agentSyndicateBetting={agentSyndicateBetting} reportSelection={reportSelection} whichToUnderline={whichToUnderline} />;
  }
  else {
    return (
      <Grid sx={{ background: 'var(--title-bar-background)' }}>
        <Grid container sx={{ styleTop }}>
          <Grid sx={{ marginLeft: "10px", background: 'var(--title-bar-background)' }} onClick={() => reportSelection("home", "desktop_menu")}>
            <Grid height="100%" container direction="column" justifyContent="center" alignItems="center">
              <Link style={{ textDecoration: "none" }} to="/clear" id={'desktop-poolx-logo'}>
                {homeIcon}
              </Link>
            </Grid>
          </Grid>
          <Grid item flexGrow={1} sx={{ background: 'var(--title-bar-background)' }}>
            <Grid container sx={{
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 'bold',
              flexDirection: 'var(--flex-direction)',
              height: "100%"
            }
            } >
              <div onClick={() => reportSelection("rounds", "desktop_menu")} height="100%">
                <Grid container direction="column">
                  <Link id="round-history" to="/roundhistory" style={whichToUnderline === '4' ? underlined : linkStyle}>
                    <Typography sx={whichToUnderline === '4' ? underlined : linkStyle}>{t('rounds')}</Typography>
                  </Link>
                </Grid>
              </div>
              {realMode && (
                <div onClick={() => reportSelection("coupons", "desktop_menu")}>
                  <Grid container direction="column">
                    <Link id="my-coupons" to="/mycoupons" style={whichToUnderline === '3' ? underlined : linkStyle}>
                      <Typography sx={whichToUnderline === '3' ? underlined : linkStyle}>{t('my_coupons')}</Typography>
                    </Link>
                  </Grid>
                </div>
              )}
              {agentSyndicateBetting && (
                <div onClick={() => reportSelection("syndicates", "desktop_menu")}>
                  <Grid container direction="column">
                    <Link id="bet-with-syndicates" to="/playagent" style={whichToUnderline === '2' ? underlined : linkStyle}>
                      <Typography sx={whichToUnderline === '2' ? underlined : linkStyle}>{t('bet_with_syndicates')}</Typography>
                    </Link>
                  </Grid>
                </div>
              )}                           

              <div onClick={() => reportSelection("help", "desktop_menu")}>
                <Grid container direction="column">
                  <Link id="help" to="/help" style={whichToUnderline === '6' ? underlined : linkStyle}>
                    <Typography sx={whichToUnderline === '6' ? underlined : linkStyle}>{t('help')}</Typography>
                  </Link>
                </Grid>
              </div>

            </Grid>
          </Grid>

          <Grid item sx={{
            background: 'var(--title-bar-background)',
            marginRight: "10px", height: 'var(--navbar-height)', justifyContent: 'center', alignItems: 'center'
          }}>
            {!hideBalance && <PlayerBalance balance={balance} loading={loadingBalance} theme={theme} />}
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
export default NavBar;