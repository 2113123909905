import { Box, Grid, SxProps, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getDateString, getRoundName } from "../common/RoundHelper";
import { PoolXNumberFormat } from "../common/CurrencyFormatter";
import { Syndicate } from "../models/syndicate";
import { Link } from 'react-router-dom'
import { GetFlexNumber, GetMixMaxFlex, gradientsC, IsLeo, IsVideo, productNamesC, themeC, UsePoolXLogoInRoundList } from "../theme/themehelper";
import { EightIcon, ThirteenIcon, TwelveIcon } from "../reusable-components";
import { ShallowRound } from "../rounds/types";
import kung8 from '../images/kung8.png';
import kung12 from '../images/kung12.png';
import kung13 from '../images/kung13.png';
import video8back from '../images/video_8_background.png';
import video12back from '../images/video_12_background.png';
import video13back from '../images/video_13_background.png';
import video8 from '../images/video8_no_background.png';
import video12 from '../images/video12_no_background.png';
import video13 from '../images/video13_no_background.png';
import mrvegas8 from '../images/mrvegas_8_small.png';
import mrvegas12 from '../images/mrvegas_12_small.png';
import mrvegas13 from '../images/mrvegas_13_small.png';
import { DbetEightIcon, DbetEightLadder, DbetSevenIcon, DbetThirteenIcon, DbetThirteenLadder, DbetTwelveIcon, DbetTwelveLadder } from "../images/dbeticons";

function SyndicateListItem({ syndicate, round, theme }: { syndicate: Syndicate, round: ShallowRound, theme: string }) {

    const { t } = useTranslation();
    const dateString = getDateString(round, t);
    const { totalAmountPerShare } = syndicate || {};
    const { amount, currency } = totalAmountPerShare || {};
    const isSmallScreen = useMediaQuery('(max-width: 620px)');

    let locale = t('locale');
    const nf = PoolXNumberFormat(locale, { style: 'currency', currency: currency, minimumFractionDigits: 2, maximumFractionDigits: 2 });
    let formattedStakePerShare = nf.format(amount);

    if (syndicate === undefined || (syndicate.coupon === undefined && !syndicate.couponHidden)) {
        return (<></>);
    }

    let borderWidth = "1px";

    let smallText: SxProps<Theme> = {
        fontSize: "14px",
        color: 'var(--text-black)',
        fontWeight: "400",
        fontFamily: 'var(--font-body)',
        margin: "0px"
    };

    let smallTextValues: SxProps<Theme> = {
        fontSize: "14px",
        color: 'var(--text-black)',
        lineHeight: "21px",
        fontWeight: "400",
        fontFamily: 'var(--font-body)',
    };

    let smallTitleUpper: SxProps<Theme> = {
        fontSize: isSmallScreen ? "16px" : "20px",
        color: 'var(--text-black)',
        fontWeight: "700",
        fontFamily: 'var(--font-title)',
        textTransform: 'uppercase',
        paddingLeft: '10px',
        paddingRight: '10px',
        maxWidth: isSmallScreen ? '200px' : "auto",
        minWidth: isSmallScreen ? '100px' : "auto",
        textWrap: isSmallScreen ? "wrap" : 'nowrap',
        textAlign: isSmallScreen ? "center" : 'left'
    };

    let stakeStyle: SxProps<Theme> = {
        fontSize: isSmallScreen ? "16px" : "20px",
        color: 'var(--text-black)',
        fontWeight: "700",
        fontFamily: 'var(--font-title)',
        textTransform: 'uppercase',
        textWrap: isSmallScreen ? "wrap" : 'nowrap',
        textAlign: isSmallScreen ? "center" : 'left'
    };

    let button: SxProps<Theme> = {
        marginTop: '10px',
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: '25px',
        paddingRight: '25px',
        gap: '4px',
        color: 'var(--button-text-color)',
        height: '32px',
        textTransform: 'var(--text-transform-button)',
        fontWeight: 'var(--font-weight-button)',
        fontSize: '12px',
        lineHeight: '18px',
        border: borderWidth + ' solid #DADADA',
        borderRadius: '5px',
        backgroundColor: "var(--button-color)",
        "&:hover": {
            backgroundColor: "var(--button-hover-color)",
            border: borderWidth + ' solid #DADADA',
        }
    }

    let logo = "";
    let gradient = 'linear-gradient(335deg,#A8301F,#FA9547,#D09C72,#B74D01)';

    let roundName = getRoundName(round, t);    
    let border = "";
    let boxShadow = "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px";    

    let mixMaxFlex = "";
    let number = "";    
    let logoWidth = "100px";
    let minMaxFlexTextColor = 'var(--text-white)';
    let numberBackgroundHeight = "64px";
    let numberBackgroundWidth = "77px";
    let numberImage = "";
    let fontFamily = 'var(--font-title)';
    let numberIcon = <></>;


    if (theme === themeC.Kung) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: "#78C4F8",
            fontWeight: "400",
            fontFamily: 'var(--font-title)',
            margin: '8px'
        };

        smallTextValues = {
            fontSize: "14px",
            color: '#FFF',
            lineHeight: "21px",
            fontWeight: "400",
            fontFamily: 'var(--font-body)',
            marginTop: '4px',
            marginBottom: '7px',
        };

        mixMaxFlex = GetMixMaxFlex(round.name, window.theme);
        number = GetFlexNumber(round.name);        
        gradient = gradientsC.Kung.Flex;                
        logoWidth = "100%";
        numberImage = kung8;
        minMaxFlexTextColor = "#27FFAA";        
        numberBackgroundHeight = "61px";
        numberBackgroundWidth = "46px";

        button.border = "none";

        if (round.name.indexOf("Max") > -1) {
            number = "13";
            gradient = gradientsC.Kung.Max;
            numberImage = kung13;
            minMaxFlexTextColor = "#34C2FF";
            numberBackgroundHeight = "64px";
            numberBackgroundWidth = "77px";
        }

        if (round.name.indexOf("Mix") > -1) {
            number = "12";
            gradient = gradientsC.Kung.Mix;
            numberImage = kung12;
            minMaxFlexTextColor = "#EE8DFF";
            numberBackgroundHeight = "62px";
            numberBackgroundWidth = "77px";
        }

        numberImage = `url(${numberImage})`;
        border = "";
    }

    if (theme === themeC.Dbet) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: "#5E5E5E",
            fontWeight: "400",
            fontFamily: 'var(--font-title)',
        };

        smallTextValues = {
            fontSize: "14px",
            color: '#232323',
            lineHeight: "21px",
            fontWeight: "400",
            fontFamily: 'var(--font-body)',
            marginTop: '4px',
            marginBottom: '7px',
        };

        mixMaxFlex = GetMixMaxFlex(round.name, window.theme);
        number = GetFlexNumber(round.name);        
        gradient = gradientsC.Dbet.Flex;                    
        logoWidth = "100%";
        numberImage = "";
        minMaxFlexTextColor = "#F4C004";        
        numberBackgroundHeight = "auto";
        numberBackgroundWidth = "110px";
        fontFamily = "Righteous";
        numberIcon =
            <Grid container direction="row" alignItems="center">
                <Grid sx={{ marginRight: "3.5px", paddingLeft: "38px" }}>
                    {DbetEightIcon(34.5, 26)}
                </Grid>
                {DbetEightLadder(14.25, 8.4)}
            </Grid>

        button.border = "none";

        if (round.name.indexOf("Hockey") > -1) {
            numberIcon =
                <Grid container direction="row" alignItems="center" >
                    <Grid sx={{ marginRight: "3.5px", paddingLeft: "38px" }}>
                        {DbetSevenIcon(34.5, 26)}
                    </Grid>
                    {DbetEightLadder(14.25, 8.4)}
                </Grid>
        }

        if (round.name.indexOf("Max") > -1) {
            number = "13";
            gradient = gradientsC.Dbet.Max;
            numberIcon =
                <Grid container direction="row" alignItems="center" >
                    <Grid sx={{ marginRight: "3.5px", paddingLeft: "30px" }}>
                        {DbetThirteenIcon(34.5, 41.3)}
                    </Grid>
                    {DbetThirteenLadder(14.25, 8.4)}
                </Grid>
        }

        if (round.name.indexOf("Mix") > -1) {
            number = "12";
            gradient = gradientsC.Dbet.Mix;
            numberIcon =
                <Grid container direction="row" alignItems="center">
                    <Grid sx={{ marginRight: "3.5px", paddingLeft: "30px" }}>
                        {DbetTwelveIcon(34.5, 42.7)}
                    </Grid>
                    {DbetTwelveLadder(14.25, 8.4)}
                </Grid>
        }

        numberImage = `url(${numberImage})`;
        border = "";
    }

    if (theme === themeC.Video) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: "#7E7E7E",
            fontWeight: "400",
            fontFamily: 'var(--font-title)',
        };

        smallTextValues = {
            fontSize: "14px",
            color: '#FFF',
            lineHeight: "21px",
            fontWeight: "400",
            fontFamily: 'var(--font-body)',
            marginTop: '4px',
            marginBottom: '7px',
        };

        mixMaxFlex = GetMixMaxFlex(round.name, window.theme);        
        gradient = `url(${video8back})`;
        logo = "";
        numberImage = `url(${video8})`;
        minMaxFlexTextColor = "#7EFFB2";        
        button.border = "none";                     
        logoWidth = "100%";
        fontFamily = "Tilt Warp";
        numberBackgroundWidth = "110px";

        if (round.name.indexOf("Max") > -1) {
            minMaxFlexTextColor = "#FFF3B7";
            numberImage = `url(${video13})`;
            gradient = `url(${video13back})`;
        }

        if (round.name.indexOf("Mix") > -1) {
            minMaxFlexTextColor = "#D47EDD";
            numberImage = `url(${video12})`;
            gradient = `url(${video12back})`;
        }

        border = "";
    }

    if (theme === themeC.MrVegas) {

        smallText = {
            fontSize: "14px",
            lineHeight: "21px",
            color: "#8AD798",
            fontWeight: "400",
            fontFamily: 'var(--font-title)',
            margin: '8px'
        };

        smallTextValues = {
            fontSize: "14px",
            color: '#FFF',
            lineHeight: "21px",
            fontWeight: "400",
            fontFamily: 'var(--font-body)',
            marginTop: '4px',
            marginBottom: '7px',
        };

        mixMaxFlex = "";        
        gradient = "";
        logo = `url(${mrvegas8})`;
        button.border = "none";                            
        logoWidth = "90px";

        if (round.name.indexOf("Max") > -1) {
            logo = `url(${mrvegas13})`;
        }

        if (round.name.indexOf("Mix") > -1) {
            logo = `url(${mrvegas12})`;
        }

        border = "";
    }

    if (theme === themeC.Blue2) {
        mixMaxFlex = "Mini";
        number = "8";        
        gradient = gradientsC.Blue2.Flex;                     
        logoWidth = "100%";

        if (roundName.indexOf("13") > -1) {
            mixMaxFlex = "Major";
            number = "13";            
            gradient = gradientsC.Blue2.Max;
        }

        if (roundName.indexOf("12") > -1) {
            mixMaxFlex = "Minor";
            number = "12";            
            gradient = gradientsC.Blue2.Mix;
        }

        border = "";
    }

    if (theme === themeC.Orange) {
        mixMaxFlex = round.name.indexOf("Hockey") > -1 ? productNamesC.Orange.Hockey : productNamesC.Orange.Flex;
        number = round.name.indexOf("Hockey") > -1 ? "7" : "8";        
        gradient = gradientsC.Orange.Flex;                   
        logoWidth = "100px";
        boxShadow = "0px 1px 3px 0px #00000040";

        if (roundName.indexOf("13") > -1) {
            mixMaxFlex = productNamesC.Orange.Max;
            number = "13";            
        }

        if (roundName.indexOf("12") > -1) {
            mixMaxFlex = productNamesC.Orange.Mix;
            number = "12";            
        }

        border = "";
    }

    if (theme === themeC.Wood) {
        mixMaxFlex = round.name.indexOf("Hockey") > -1 ? productNamesC.Orange.Hockey : productNamesC.Orange.Flex;
        number = round.name.indexOf("Hockey") > -1 ? "7" : "8";        
        gradient = gradientsC.Wood.Flex;                 
        logoWidth = "100px";
        boxShadow = "0px 1px 3px 0px #00000040";

        if (roundName.indexOf("13") > -1) {
            mixMaxFlex = productNamesC.Orange.Max;
            number = "13";            
        }

        if (roundName.indexOf("12") > -1) {
            mixMaxFlex = productNamesC.Orange.Mix;
            number = "12";            
        }

        border = "";
    }

    if (theme === themeC.Lion) {
        mixMaxFlex = round.name.indexOf("Hockey") > -1 ? productNamesC.Orange.Hockey : productNamesC.Orange.Flex;
        number = round.name.indexOf("Hockey") > -1 ? "7" : "8";        
        gradient = gradientsC.Lion.Flex;                      
        logoWidth = "100px";
        boxShadow = "0px 1px 3px 0px #00000040";

        if (roundName.indexOf("13") > -1) {
            mixMaxFlex = productNamesC.Orange.Max;
            number = "13";            
        }

        if (roundName.indexOf("12") > -1) {
            mixMaxFlex = productNamesC.Orange.Mix;
            number = "12";            
        }

        border = "";
    }

    if (theme === themeC.Green) {
        mixMaxFlex = round.name.indexOf("Hockey") > -1 ? productNamesC.Orange.Hockey : productNamesC.Orange.Flex;
        number = round.name.indexOf("Hockey") > -1 ? "7" : "8";        
        gradient = gradientsC.Green.Flex;                    
        logoWidth = "100px";
        boxShadow = "0px 1px 3px 0px #00000040";

        if (roundName.indexOf("13") > -1) {
            mixMaxFlex = productNamesC.Orange.Max;
            number = "13";            
        }

        if (roundName.indexOf("12") > -1) {
            mixMaxFlex = productNamesC.Orange.Mix;
            number = "12";            
        }

        border = "";
    }

    let icon: JSX.Element = <></>;

    if (theme === themeC.Light) {
        gradient = "linear-gradient( 0deg, #E0D8EE 0%, #E0D8EE 100%)";        
        logoWidth = "100%";
        icon = <EightIcon height="40" />;

        if (roundName.indexOf("Max") > -1) {
            mixMaxFlex = "Max";
            number = "13";            
            gradient = "linear-gradient( 0deg, #B8CCF1 0%, #B8CCF1 100%)";
            icon = <ThirteenIcon height="40" />;
        }

        if (roundName.indexOf("Mix") > -1) {
            mixMaxFlex = "Mix";
            number = "12";            
            gradient = "linear-gradient( 0deg, #FAEEAE 0%, #FAEEAE 100%)";
            icon = <TwelveIcon height="40" />;
        }

        border = "";
    }

    return (
        <Grid container flexWrap="nowrap" direction='row' alignItems="center" component={Link} to={`/syndicate/${syndicate.id}`} sx={{
            width: "100%",
            border: border,
            borderRadius: 'var(--radius)',
            background: 'var(--list-boxes-primary)',
            backgroundOrigin: "border-box",
            backgroundClip: "padding-box, border-box",
            padding: "0px",
            margin: "10px",
            minHeight: isSmallScreen ? "90px" : "",
            textDecoration: 'none',
            boxShadow: boxShadow
        }}>

            <Grid container direction='column' sx={{
                width: "120px",
                backgroundImage: gradient,
                height: "100%",
                backgroundSize: "cover",
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: "var(--radius)"

            }}>
                <Grid item sx={{
                    width: logoWidth,
                    backgroundImage: logo,
                    backgroundSize: 'cover',
                    marginLeft: "0px",
                    marginRight: "0px",
                }}>

                    {(window.theme === themeC.Blue2 || IsLeo(window.theme)) &&
                        <Grid direction="column" container alignItems="center" sx={{
                            marginTop: "5px",
                            padding: (window.theme === themeC.Blue2) ? "10px" : ""
                        }}>
                            <Typography sx={{
                                fontSize: "14px",
                                fontFamily: 'var(--font-title)',
                                color: 'var(--text-white)',
                                fontWeight: "400",
                                textTransform: "uppercase",
                                marginTop: "5px"
                            }}>
                                {mixMaxFlex}
                            </Typography>
                            <Typography sx={{
                                fontSize: "32px",
                                fontFamily: 'var(--font-title)',
                                color: 'var(--text-white)',
                                fontWeight: "800",
                                marginTop: "-10px"
                            }}>
                                {number}
                            </Typography>
                        </Grid>
                    }

                    {IsVideo(window.theme) &&
                        <Grid direction="column" container alignItems="center" sx={{ padding: "5px", marginTop: "3px" }}>
                            <Typography sx={{
                                fontSize: "13px",
                                fontFamily: fontFamily,
                                color: minMaxFlexTextColor,
                                fontWeight: window.theme === themeC.Kung ? "700" : "400",
                                textTransform: (window.theme === themeC.Video) ? "none" : "uppercase",
                                marginTop: "0px"
                            }}>
                                {mixMaxFlex}
                            </Typography>
                            <Box
                                sx={{
                                    backgroundImage: numberImage,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    height: numberBackgroundHeight,
                                    width: numberBackgroundWidth,
                                    marginTop: window.theme === themeC.Video ? "-8px" : "0px"
                                }}
                            >
                                {numberIcon}
                            </Box>

                        </Grid>
                    }                   

                    {(UsePoolXLogoInRoundList(window.theme)) &&
                        <Grid direction="column" container alignItems="center">
                            <Box
                                sx={{
                                    backgroundImage: numberImage,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    padding: "20px"
                                }}
                            >{icon}
                            </Box>
                        </Grid>
                    }

                </Grid>
            </Grid>

            <Grid container sx={{ flexGrow: 1 }} direction='column' alignItems='center'>
                <Typography sx={smallText}>{dateString}</Typography>
                <Typography sx={smallTitleUpper} >{syndicate.name}</Typography>
            </Grid>

            {!isSmallScreen &&
                <>
                    {syndicate.couponHidden ?
                        <Grid container sx={{ maxWidth: "150px" }} direction='column' alignItems='center' justifyContent='center'>
                            <Typography sx={smallText}>{t('total_cost') + ': '}</Typography>
                            <Typography sx={smallTextValues}>{nf.format((Number(syndicate.stakePerShare.amount) + Number(syndicate.agentFeePerShare.amount)) * syndicate.targetNShares)}</Typography>
                        </Grid>
                        :
                        <Grid container sx={{ maxWidth: "150px" }} direction='column' alignItems='center' justifyContent='center'>
                            <Typography sx={smallText}>{t('number_of_rows') + ': '}</Typography>
                            <Typography sx={smallTextValues}>{syndicate.coupon.totalRows}</Typography>
                        </Grid>
                    }
                    <Grid container sx={{ maxWidth: "100px" }} direction='column' alignItems='center' justifyContent='center'>
                        <Typography sx={smallText}>{t('shares') + ': '}</Typography>
                        {syndicate.nSharesHidden ?
                            <Typography sx={smallTextValues}>{syndicate.targetNShares}</Typography>
                            :
                            <Typography sx={smallTextValues}>{'(' + syndicate.nShares + '/' + syndicate.targetNShares + ')'}</Typography>
                        }
                    </Grid>
                </>
            }
            <Grid container sx={{ maxWidth: isSmallScreen ? "100px" : "150px", minWidth: isSmallScreen ? "100px" : "150px" }} direction='column' alignItems='center' justifyContent='center'>
                <Typography sx={stakeStyle}>{formattedStakePerShare}</Typography>
            </Grid>

        </Grid>
    );

}

export default SyndicateListItem;