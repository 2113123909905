import { useState } from 'react';
import { Wrapper } from './styles';
import {
  H2,
  CogIcon,
  RobotIcon, InfoIcon, H2Money,
} from '../../../reusable-components';
import { useSimplePurchase } from '../../../rounds/usePurchase';
import WinInfoRound from '../wininforound';
import { useTranslation } from "react-i18next";
import { getSuggestion } from './components/propick';
import MediaQuery, { useMediaQuery } from "react-responsive/src";
import { operatorAdviceBetSuccess, operatorAdviceScrollToTop } from "../../../App";
import { usePlayer } from "../../../player/playerContext";
import { formatStringCurrency } from "../../../common/CurrencyFormatter";
import { getBackground, getDateString, getJackpot, getRoundStatus } from '../../../common/RoundHelper';
import CouponHeaderSlim from './components/couponheaderslim/couponheaderslim';
import ShowResultOfBet from './components/showresultofbet';
import { UseSlimCouponHeader, IsRightToLeftTheme } from '../../../theme/themehelper';
import SlimCoupon from '../../../slimcoupon/slimcoupon';

const CouponForm = ({
  round,  
  onChangeFixture,  
  setShowRoundInfo,
  showRoundInfo,
  proPickSuggestion
}) => {
  const [stakeMultiplier, setStakeMultiplier] = useState(1);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showResultOfBet, setShowResultOfBet] = useState(false);
  const [amount, setAmount] = useState(0);
  const purchase = useSimplePurchase(round, stakeMultiplier);
  const [showRSystem, setShowRSystem] = useState(false);
  const [showProPick, setShowProPick] = useState(false);
  const [incomingRowsCalculated, setIncomingRowsCalculated] = useState(false);
  const [proPickRows, setProPickRows] = useState(undefined);
  const { t } = useTranslation();
  const isMobileScreen = useMediaQuery({ query: '(max-width: 950px)' })
  const [submitting, setSubmitting] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);
  const [submitError, setSubmitError] = useState(undefined);

  const [player, config] = usePlayer();
  const rSystemsActive = config.rSystemBetting;

  const handleShowRSystemClick = () => {
    setShowRSystem(true);
    operatorAdviceScrollToTop(500);
  }

  const handleErrorMessageClose = () => {
    if(submitFailed){
      setSubmitFailed(false);
      setSubmitError(undefined);
      setShowConfirm(false);     
    }    
  }

  if (proPickSuggestion && !incomingRowsCalculated &&
    (proPickSuggestion.numFull > 0 || proPickSuggestion.numHalf > 0)) {
    setIncomingRowsCalculated(true);
    getSuggestion(proPickSuggestion, round.fixtures, setProPickRows);
  }

  let isFullVersion = !UseSlimCouponHeader(window.theme);
  let isRightToLeft = IsRightToLeftTheme(window.theme);

  let errorResponse = {};

  const onSubmit = async (coupon) => {
    setSubmitting(true);
    let res = await purchase(coupon);
    const data = await res.json();
    setSubmitting(false);
    setProPickRows(undefined);
    if (res.status === 200) {
      setSubmitFailed(false);
      setSubmitError(undefined);
      //console.log(data);
      if (data && data.transactionId) {
        let betSuccessEvent = {
          type: "bet_success",
          amount: data.amount,
          currency: data.currency,
          transactionId: data.transactionId,
          roundId: data.roundId,
        }
        operatorAdviceBetSuccess(betSuccessEvent);
        setShowConfirm(false);
        setAmount(data.amount);
        setShowResultOfBet(true);
        window.gtag("event", "purchase", {
          value: data.amount,
          currency: data.currency,
          coupon: data.roundId,
          transaction_id: data.transactionId,
          stakeMultiplier: stakeMultiplier,
          items: [
            {
              item_id: data.couponShareId,
              coupon: data.couponId,
              item_category: data.roundId,
              price: data.amount,
              item_category2: coupon.bets[0].system.type
            }
          ]
        });
      }
      return res;
    } else {
      errorResponse = data
      setSubmitError(errorResponse);
      setSubmitFailed(true);
      return errorResponse ;
    }
  }

  const { openTo } = round || {};

  const turnoverAmount = round.operatorTurnover ? round.operatorTurnover.amount : 'NA';
  const turnoverCurrency = round.operatorTurnover ? round.operatorTurnover.currency : 'NA';
  const startDate = new Date(openTo);

  let jackpot = getJackpot(turnoverCurrency, round);

  let roundStatus = getRoundStatus(round, startDate, t);

  const isAfterDeadline = startDate < Date.now();  

  function setSlimProPick(value) {
    setShowProPick(value);
  }

  function setSlimShowSystem(value) {
    setShowRSystem(value);
  }

  return (
    <Wrapper>
      {showResultOfBet && <ShowResultOfBet t={t} toggle={setShowResultOfBet} amount={amount} />}

      <>
        {isFullVersion ?

          <div className="round-back-div" style={{ background: getBackground(round) }}>
            <div className="stats">
              <div className="date-flex underline" id={"start-date-wrapper"}>
                <H2 className="black-text">{getDateString(round, t)}</H2>
              </div>
              {jackpot &&
                <div id={"turnover-wrapper"} className="underline">
                  <span className="small-span rtlAdjust">{t('jackpot')}</span>
                  <H2Money className="black-text rtlAdjust">{formatStringCurrency(jackpot, turnoverCurrency, t, true)}</H2Money>
                </div>
              }
              <div id={"turnover-wrapper"} className="underline">
                <span className="small-span rtlAdjust">{t('turnover')}</span>
                <H2Money className="black-text rtlAdjust">{formatStringCurrency(turnoverAmount, turnoverCurrency, t, true)}</H2Money>
              </div>

              <MediaQuery minWidth={950}>
                <div>
                  <div>
                    <span className="small-span rtlAdjust">
                      <div>Status</div>
                    </span>
                    <H2 className="black-text rtlAdjust">{roundStatus}</H2>
                  </div>
                </div>
              </MediaQuery>
            </div>
            {isAfterDeadline ? <div></div> :

              <div className={isRightToLeft ? "explain-rtl" : "explain"}>
                <div className="icon-flex" onClick={() => setShowRoundInfo(!showRoundInfo)}>
                  <InfoIcon height={isMobileScreen ? "20" : "30"} width={isMobileScreen ? "20" : "30"} />
                  <span>{t('round_information_short')}</span>
                </div>
                <div className="icon-flex" onClick={() => setShowProPick(true)}>
                  <RobotIcon height={isMobileScreen ? "20" : "30"} width={isMobileScreen ? "20" : "30"} />
                  <span>{t('pro_pick')}</span>
                </div>
                <MediaQuery minWidth={601}>
                  {rSystemsActive && (
                    <div>
                      <div className="icon-flex" onClick={() => handleShowRSystemClick()}>
                        <CogIcon height={isMobileScreen ? "20 " : "30"} width={isMobileScreen ? "20 " : "30"} />
                        <span>{t('system')}</span>
                      </div>
                    </div>)}
                </MediaQuery>

              </div>
            }
          </div> :
          <div>
            <CouponHeaderSlim jackpot={jackpot} round={round} t={t} turnoverAmount={turnoverAmount} turnoverCurrency={turnoverCurrency}
              setShowRoundInfo={setShowRoundInfo} setShowProPick={setSlimProPick} setShowSystem={setSlimShowSystem}/>

          </div>
        }

        <SlimCoupon round={round} onSubmit={onSubmit} theme={window.theme}
          showProPick={showProPick} setShowProPick={setShowProPick} showConfirm={showConfirm} setShowConfirm={setShowConfirm}
          submitting={submitting} submitFailed={submitFailed} onErrorMsgClose={handleErrorMessageClose} submitError={submitError} stakeMultiplier={stakeMultiplier} setStakeMultiplier={setStakeMultiplier}
          onChangeFixture={onChangeFixture} showRoundInfo={showRoundInfo} setShowRoundInfo={setShowRoundInfo} showRSystem={showRSystem} setShowRSystem={setShowRSystem}
          proPickRows={proPickRows} setProPickRows={setProPickRows} />

        {(isAfterDeadline && (round.operatorPrizePlan)) && (

          <WinInfoRound round={round} isRightToLeft={isRightToLeft} ></WinInfoRound>

        )}
      </>

    </Wrapper>
  );
}

export default CouponForm;