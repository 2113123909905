import NavBar from '../navbar';
import image from '../images/footballfield.jpg';
import { Button, H2, H3, Label } from '../reusable-components';
import { useTranslation } from "react-i18next";
import useShallowRounds from '../rounds/useShallowRounds';
import { ChangeEvent, useState } from 'react';
import useApiBetting from './useApiBetting';
import { getDateString, getRoundName } from '../common/RoundHelper';
import Moment from 'react-moment';
import ConfirmPaymentApi from './confirmpaymentapi';
import moment from 'moment';
import dbetImage from '../images/dbet_syndicate.jpeg';
import videoImage from '../images/video_syndicate.png';
import mrvegasImage from '../images/mrvegas_syndicate.jpeg';
import orangeStadium from '../images/expekt_background.png';
import woodLanding from '../images/wood_landing.png';
import leoLanding from '../images/lion_landing.png';
import { gradientsC, IsLeo, themeC } from '../theme/themehelper';
import { Grid, List, ListItem, SxProps, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, useMediaQuery } from '@mui/material';
import { ShallowRound } from '../rounds/types';

export default function ApiBetting() {
  const { t } = useTranslation();
  const [shallowRounds] = useShallowRounds(true, false);
  const [file, setFile] = useState<File>();
  const [info, setInfo] = useState<string | null>();
  const { uploadFile } = useApiBetting();
  const [response, setResponse] = useState<any>();
  const [showConfirm, setShowConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width: 950px)');

  let rounds = shallowRounds?.rounds;
  let backgroundImage = `url(${image})`;
  let backgroundHeight = "376px";

  if (window.theme === themeC.Blue2) {
    backgroundImage = gradientsC.Blue2.Landing;
  }

  if (window.theme === themeC.Dbet) {
    backgroundImage = `url(${dbetImage})`;
  }

  if (window.theme === themeC.Video) {
    backgroundImage = `url(${videoImage})`;
  }

  if (window.theme === themeC.MrVegas) {
    backgroundImage = `url(${mrvegasImage})`;
  }

  if (window.theme === themeC.Orange) {
    backgroundImage = `url(${orangeStadium})`;
  }

  if (window.theme === themeC.Wood) {
    backgroundImage = `url(${woodLanding})`;
  }

  if (window.theme === themeC.Lion) {
    backgroundImage = `url(${leoLanding})`;
  }

  let locale = t('locale');

  if (IsLeo(window.theme)) {
    backgroundHeight = "260px";
  }

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const closeConfirm = () => {
    setShowConfirm(false);
    setFile(undefined);
    setInfo(null);
  }

  const handleUploadClick = () => {
    if (!file) {
      return;
    }
    setShowConfirm(true);
  }

  const uploadFileAfterConfirm = () => {
    setSubmitting(true);

    uploadFile(file as File).then(resp => {
      setResponse(resp);
      setSubmitting(false);
    })
  }

  let fileReader = new FileReader();
  let round = undefined;
  let count = undefined;

  if (file) {
    fileReader.onload = function () {
      setInfo(fileReader.result as string);
    };
    fileReader.readAsText(file)
  }

  if (info) {
    var roundId = info.split('\n')[0];
    roundId = roundId.replace('\r', '');
    round = rounds?.filter(r => r.id === roundId)[0];
    let lines = info.split('\n');
    count = lines.filter(l => l.indexOf('E') >= 0).length;
  }

  let roundName = getRoundName(round, t);

  let futureRounds: ShallowRound[] = [];
  if (rounds !== undefined) {
    futureRounds = rounds.filter(r => moment(r.openTo) > moment(new Date()));
  }

  const styleInfoBox: SxProps<Theme> = {
    background: 'var(--list-boxes-primary)',
    width: '300px'
  }

  const styleTile: SxProps<Theme> = {
    width: isSmallScreen ? '90%' : '600px',
    padding: '10px 10px 10px 30px',
    background: 'var(--list-boxes-primary)',
    margin: '10px 0px 60px 0px'
  }

  const styleTitle: SxProps<Theme> = {
    fontSize: '14px',
    fontWeight: 'bold',
    margin: '10px 10px 10px 0px'
  }
  const styleValue: SxProps<Theme> = {
    fontSize: '14px',
    margin: '10px 10px 10px 0px'
  }

  const styleInfoRow: SxProps<Theme> = {
    justifyContent: "space-between"
  }

  return (
    <>
      <NavBar whichToUnderline="7" balanceUpdateId="0" />

      <Grid container sx={{
        width: "100%",
        height: backgroundHeight,
        background: backgroundImage,
        justifyContent: "center",
        alignItems: "center",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        color: "var(--text-white)"
      }}>
        <H2 color="#fff">
          {t('api_betting')}
        </H2>
      </Grid>
      <Grid container alignItems="center" direction="column" sx={{
        background: 'var(--list-background)',
        padding: "20px"
      }}>
        <Grid container direction="column" sx={styleTile}>

          <H3>{t('api:info')}</H3>

          {
            <Grid container direction="column" sx={styleInfoBox}>

              <Grid container sx={styleInfoRow}>
                <Typography sx={styleTitle}>{t('file')}:</Typography>
                <Typography sx={styleValue}>{file && `${file.name}`}</Typography>
              </Grid>
              <Grid container sx={styleInfoRow}>
                <Typography sx={styleTitle}>{t('round')}:</Typography>
                <Typography sx={styleValue}>{round && round.id}</Typography>
              </Grid>
              <Grid container sx={styleInfoRow}>
                <Typography sx={styleTitle}>{t('name')}:</Typography>
                <Typography sx={styleValue}>{round && roundName}</Typography>
              </Grid>
              <Grid container sx={styleInfoRow}>
                <Typography sx={styleTitle}>{t('starts')}:</Typography>
                <Typography sx={styleValue}>{round && <Moment format={"yyyy-MM-DD"} locale={locale}>{round.openTo}</Moment>}</Typography>
              </Grid>
              <Grid container sx={styleInfoRow}>
                <Typography sx={styleTitle}>{t('number_of_rows')}:</Typography>
                <Typography sx={styleValue}>{count}</Typography>
              </Grid>
            </Grid>
          }

          <Grid container direction={isSmallScreen ? "column" : "row"} sx={{ alignItems: "center", padding: "10px" }}>
            <Grid sx={{ padding: "10px" }}>
              <input
                style={{
                  opacity: 0,
                  width: "0.1px",
                  height: "0.1px",
                  position: "absolute"
                }}

                id="file" type="file" onChange={e => handleFileChange(e)} />
              <Label htmlFor="file">
                {t('select_file')}
              </Label>
            </Grid>
            <Grid sx={{ padding: "10px" }}>
              <Button className="btn-upload" disabled={round === undefined || file === undefined || count === 0} onClick={() => handleUploadClick()}>{t('upload')}</Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid container sx={styleTile}>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={styleTitle}>{t('id')}</TableCell>
                <TableCell sx={styleTitle}>{t('starts')}</TableCell>
                <TableCell sx={styleTitle}>{t('name')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                futureRounds.map((round, index) => {

                  return (
                    <TableRow key={'row' + index}>
                      <TableCell sx={styleValue}>{round.id}</TableCell>
                      <TableCell sx={styleValue}>{getDateString(round, t)}</TableCell>
                      <TableCell sx={styleValue}>{getRoundName(round, t)}</TableCell>
                    </TableRow>);

                })
              }
            </TableBody>
          </Table>

        </Grid>

        <Grid container sx={styleTile}>

          <H3>{t('api:format.title')}</H3>

          <Grid container direction="column">
            <List sx={{ listStyleType: "disc", padding: "20px" }}>
              <ListItem sx={{ display: 'list-item' }}>{t('api:format.p1')}</ListItem>
              <ListItem sx={{ display: 'list-item' }}>{t('api:format.p2')}</ListItem>
              <ListItem sx={{ display: 'list-item' }}>{t('api:format.p3')}</ListItem>
              <ListItem sx={{ display: 'list-item' }}>{t('api:format.p4')}</ListItem>
              <ListItem sx={{ display: 'list-item' }}>{t('api:format.p5')}</ListItem>
            </List>

            <Grid container direction="column">
              <H3>{t('api:example.title')}:</H3>
              <Typography sx={styleValue}> {t('api:example.row1')}</Typography>
              <Typography sx={styleValue}>{t('api:example.row2')}</Typography>
              <Typography sx={styleValue}>{t('api:example.row3')}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {showConfirm && round && (
        <ConfirmPaymentApi
          toggle={closeConfirm}
          submitting={submitting}
          response={response}
          uploadFile={uploadFileAfterConfirm}
          roundId={round.id}
          count={count}
        />
      )}
    </>
  );
}